import { QueryParamsEnum } from '../enums';
const createPoint = (str) => {
    const [id = '', name = '', type = ''] = (str === null || str === void 0 ? void 0 : str.split(':')) || [];
    return { id, name, type };
};
export const expandSearchParams = (query) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    if (!query) {
        return null;
    }
    const startDate = (_b = (_a = query[QueryParamsEnum.CHECK_IN_DATE]) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '';
    const endDate = (_d = (_c = query[QueryParamsEnum.CHECK_OUT_DATE]) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '';
    const destinationIds = query[QueryParamsEnum.DESTINATION_IDS]
        ? (_e = query[QueryParamsEnum.DESTINATION_IDS]) === null || _e === void 0 ? void 0 : _e.split('|').map((destId) => { var _a; return (_a = parseInt(destId)) !== null && _a !== void 0 ? _a : destId; })
        : undefined;
    const destinationId = query[QueryParamsEnum.DESTINATION_ID] && parseInt(query[QueryParamsEnum.DESTINATION_ID]);
    const hotelIds = query[QueryParamsEnum.HOTEL_IDS]
        ? (_f = query[QueryParamsEnum.HOTEL_IDS]) === null || _f === void 0 ? void 0 : _f.split('|').map((hotelId) => { var _a; return (_a = parseInt(hotelId)) !== null && _a !== void 0 ? _a : hotelId; })
        : undefined;
    const hotelId = query[QueryParamsEnum.HOTEL_ID] && parseInt(query[QueryParamsEnum.HOTEL_ID]);
    const tripType = (_g = query[QueryParamsEnum.TRIP_TYPE]) === null || _g === void 0 ? void 0 : _g.toString();
    const departureAirports = (_h = query[QueryParamsEnum.DEPARTURE_AIRPORTS]) === null || _h === void 0 ? void 0 : _h.toString().split(',');
    const arrivalAirports = (_j = query[QueryParamsEnum.ARRIVAL_AIRPORT]) === null || _j === void 0 ? void 0 : _j.toString().split(',');
    const destinations = (_k = query[QueryParamsEnum.DESTINATIONS]) === null || _k === void 0 ? void 0 : _k.split('-').map((el) => {
        const [id, type] = el.split(':').map((sp) => +sp);
        return { id, type };
    });
    const roomObj = Object.entries(query)
        // Filter all the key-value pairs in the query to just the ones relating to rooms
        .filter(([key]) => key.startsWith(`${QueryParamsEnum.ROOMS}.`))
        // Group the pairs together into one object per room i.e. { 0: { adults: ..., childAges: ... }, 1: { adults: ..., childAges: ...}}
        .reduce((acc, [key, val]) => {
        const [_, index, type] = key.split('.');
        return Object.assign(Object.assign({}, acc), { [index]: Object.assign(Object.assign({}, (acc[index] || {})), { [type]: val === null || val === void 0 ? void 0 : val.toString() }) });
    }, {});
    // Take the groups object and flatten it into an array of rooms with the correct types
    const rooms = Object.entries(roomObj).map(([_, val]) => {
        var _a, _b;
        return ({
            adults: Number(val[QueryParamsEnum.ADULTS] || '0'),
            childAges: ((_b = (_a = val[QueryParamsEnum.CHILD_AGES]) === null || _a === void 0 ? void 0 : _a.split(',')) === null || _b === void 0 ? void 0 : _b.map((age) => Number(age))) || [],
        });
    });
    const adults = Number(query[QueryParamsEnum.ADULTS] || '0');
    const childAges = query[QueryParamsEnum.CHILD_AGES]
        ? (_l = query[QueryParamsEnum.CHILD_AGES].split(',')) === null || _l === void 0 ? void 0 : _l.map((age) => Number(age))
        : [];
    const cabin = query[QueryParamsEnum.CABIN] && parseInt(query[QueryParamsEnum.CABIN]);
    const dateSearchType = query[QueryParamsEnum.DATE_SEARCH_TYPE];
    const duration = query[QueryParamsEnum.DURATION] && parseInt(query[QueryParamsEnum.DURATION]);
    const flexibleDays = query[QueryParamsEnum.FLEXIBLE_DAYS] && parseInt(query[QueryParamsEnum.FLEXIBLE_DAYS]);
    const pickUpPoint = createPoint(query[QueryParamsEnum.PICK_UP_POINT]);
    const dropOffPoint = createPoint(query[QueryParamsEnum.DROP_OFF_POINT]);
    const flightJourneyType = query[QueryParamsEnum.FLIGHT_JOURNEY_TYPE] || '';
    const userId = (_m = query[QueryParamsEnum.USER_ID]) === null || _m === void 0 ? void 0 : _m.replace('{', '').replace('}', '').replace('%7B', '').replace('%7D', '');
    return {
        startDate,
        endDate,
        destinationIds: destinationIds || (destinationId && [destinationId]),
        hotelIds: hotelIds || (hotelId && [hotelId]),
        tripType,
        rooms,
        departureAirports,
        adults,
        childAges,
        arrivalAirports,
        destinations,
        destType: Number(query[QueryParamsEnum.TYPE]),
        cabin,
        dateSearchType,
        duration,
        flexibleDays,
        pickUpPoint,
        dropOffPoint,
        flightJourneyType,
        userId,
    };
};
