var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { initializeApollo } from '../../apollo';
import { TripType, } from '../../contracts/types';
import { convertSearchStateToHolidayRequest, convertSearchStateToHotelRequest, convertSearchStateToFlexibleHotelRequest, convertSearchStateToFlexibleHolidayRequest, convertSearchStateToFlightRequest, } from '../../util';
import { readSearchParamFragment, } from './useSearch';
import { currentTripStateIdVar } from './currentTripState';
import { expandSearchParams } from '../../util/search/expandSearchParams';
import { validateSearchQueryParams } from '../../util/search/validateSearchQueryParams';
import { FLEXIBLE_HOLIDAY_SEARCH_MUTATION, FLEXIBLE_HOTEL_SEARCH_MUTATION, HOLIDAY_SEARCH_MUTATION, HOTEL_SEARCH_MUTATION, SEARCH_PARAMS_QUERY, TRANSFER_SEARCH_MUTATION, FLIGHT_SEARCH_MUTATION, } from './searchGql';
import { convertSearchStateToTransferSearchRequest } from '../../util/convertSearchStateToTransferSearchRequest';
export const flightSearch = (querySearchParameters, newSearchSideEffect) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f;
    const client = initializeApollo();
    const flexibleHolidayArgs = convertSearchStateToFlightRequest(querySearchParameters);
    const result = yield client.mutate({
        mutation: FLIGHT_SEARCH_MUTATION,
        variables: { request: flexibleHolidayArgs },
    });
    const newTripStateId = (_c = (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.flightSearch) === null || _b === void 0 ? void 0 : _b.tripStateId) !== null && _c !== void 0 ? _c : '';
    const newSearchParameters = (_f = (_e = (_d = result.data) === null || _d === void 0 ? void 0 : _d.flightSearch) === null || _e === void 0 ? void 0 : _e.searchParameters) !== null && _f !== void 0 ? _f : null;
    if (newTripStateId) {
        currentTripStateIdVar(newTripStateId);
        // Update trip Id in browser url
        /* the second arg is for replacing the current URL in the browser histoy */
        newSearchSideEffect(newSearchParameters, true, true);
    }
});
export const hotelHolidaySearch = (querySearchParameters, newSearchSideEffect) => __awaiter(void 0, void 0, void 0, function* () {
    var _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    const tripType = querySearchParameters === null || querySearchParameters === void 0 ? void 0 : querySearchParameters.tripType;
    let newTripStateId;
    let newSearchParameters;
    const userIdInLocalStorage = typeof window !== 'undefined' && window.localStorage.getItem('userId');
    const userId = userIdInLocalStorage ? JSON.parse(userIdInLocalStorage) : undefined;
    const querySearchParametersWithUser = Object.assign(Object.assign({}, querySearchParameters), { userId: userId !== null && userId !== void 0 ? userId : querySearchParameters === null || querySearchParameters === void 0 ? void 0 : querySearchParameters.userId });
    const client = initializeApollo();
    if (tripType === TripType.Holiday && ((_g = querySearchParameters === null || querySearchParameters === void 0 ? void 0 : querySearchParameters.destinations) === null || _g === void 0 ? void 0 : _g.length)) {
        const flexibleHolidayArgs = convertSearchStateToFlexibleHolidayRequest(querySearchParametersWithUser);
        const result = yield client.mutate({
            mutation: FLEXIBLE_HOLIDAY_SEARCH_MUTATION,
            variables: { request: flexibleHolidayArgs },
        });
        newTripStateId = (_k = (_j = (_h = result.data) === null || _h === void 0 ? void 0 : _h.flexibleHolidaySearch) === null || _j === void 0 ? void 0 : _j.tripStateId) !== null && _k !== void 0 ? _k : '';
        newSearchParameters = (_o = (_m = (_l = result.data) === null || _l === void 0 ? void 0 : _l.flexibleHolidaySearch) === null || _m === void 0 ? void 0 : _m.searchParameters) !== null && _o !== void 0 ? _o : null;
    }
    else if (tripType === TripType.Holiday) {
        // Type cast safe here as necessary params checked above
        const holidayArgs = convertSearchStateToHolidayRequest(querySearchParametersWithUser);
        const result = yield client.mutate({
            mutation: HOLIDAY_SEARCH_MUTATION,
            variables: { request: holidayArgs },
        });
        newTripStateId = ((_q = (_p = result.data) === null || _p === void 0 ? void 0 : _p.holidaySearch) === null || _q === void 0 ? void 0 : _q.tripStateId) || '';
        newSearchParameters = ((_s = (_r = result.data) === null || _r === void 0 ? void 0 : _r.holidaySearch) === null || _s === void 0 ? void 0 : _s.searchParameters) || null;
    }
    else if ((_t = querySearchParameters === null || querySearchParameters === void 0 ? void 0 : querySearchParameters.destinations) === null || _t === void 0 ? void 0 : _t.length) {
        const hotelArgs = convertSearchStateToFlexibleHotelRequest(querySearchParametersWithUser);
        const result = yield client.mutate({
            mutation: FLEXIBLE_HOTEL_SEARCH_MUTATION,
            variables: { request: hotelArgs },
        });
        newTripStateId = (_v = (_u = result.data) === null || _u === void 0 ? void 0 : _u.flexibleHotelSearch) === null || _v === void 0 ? void 0 : _v.tripStateId;
        newSearchParameters = (_x = (_w = result.data) === null || _w === void 0 ? void 0 : _w.flexibleHotelSearch) === null || _x === void 0 ? void 0 : _x.searchParameters;
    }
    else {
        const hotelArgs = convertSearchStateToHotelRequest(querySearchParametersWithUser);
        const result = yield client.mutate({
            mutation: HOTEL_SEARCH_MUTATION,
            variables: { request: hotelArgs },
        });
        newTripStateId = (_z = (_y = result.data) === null || _y === void 0 ? void 0 : _y.hotelSearch) === null || _z === void 0 ? void 0 : _z.tripStateId;
        newSearchParameters = (_1 = (_0 = result.data) === null || _0 === void 0 ? void 0 : _0.hotelSearch) === null || _1 === void 0 ? void 0 : _1.searchParameters;
    }
    if (newTripStateId) {
        currentTripStateIdVar(newTripStateId);
        // Update trip Id in browser url
        /* the second arg is for replacing the current URL in the browser histoy */
        newSearchSideEffect(newSearchParameters, true, true);
    }
});
export const transferSearch = (querySearchParameters, newSearchSideEffect) => __awaiter(void 0, void 0, void 0, function* () {
    var _2, _3, _4, _5, _6, _7;
    const client = initializeApollo();
    const transferArgs = convertSearchStateToTransferSearchRequest(querySearchParameters);
    const result = yield client.mutate({
        mutation: TRANSFER_SEARCH_MUTATION,
        variables: { request: transferArgs },
    });
    const newTripStateId = (_4 = (_3 = (_2 = result.data) === null || _2 === void 0 ? void 0 : _2.transferSearch) === null || _3 === void 0 ? void 0 : _3.tripStateId) !== null && _4 !== void 0 ? _4 : '';
    const newSearchParameters = (_7 = (_6 = (_5 = result.data) === null || _5 === void 0 ? void 0 : _5.transferSearch) === null || _6 === void 0 ? void 0 : _6.searchParameters) !== null && _7 !== void 0 ? _7 : null;
    if (newTripStateId) {
        currentTripStateIdVar(newTripStateId);
        // Update trip Id in browser url
        /* the second arg is for replacing the current URL in the browser histoy */
        newSearchSideEffect(newSearchParameters, true, false);
    }
});
export const newSearch = (newSearchSideEffect, query) => __awaiter(void 0, void 0, void 0, function* () {
    if (!query)
        return;
    const querySearchParameters = expandSearchParams(query);
    if (!validateSearchQueryParams(query)) {
        // eslint-disable-next-line no-console
        console.error('Not enough data to build new search');
    }
    else {
        const tripType = querySearchParameters === null || querySearchParameters === void 0 ? void 0 : querySearchParameters.tripType;
        if (tripType === TripType.Transfer) {
            yield transferSearch(querySearchParameters, newSearchSideEffect);
        }
        else if (tripType === TripType.Flight) {
            yield flightSearch(querySearchParameters, newSearchSideEffect);
        }
        else {
            yield hotelHolidaySearch(querySearchParameters, newSearchSideEffect);
        }
    }
});
export const setSearchState = (tripStateId, query, newSearchSideEffect) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (!tripStateId && validateSearchQueryParams(query)) {
            yield newSearch(newSearchSideEffect, query);
            return;
        }
        // safe to typecast as early return for !tripStateId above
        const searchParams = readSearchParamFragment(tripStateId);
        if (searchParams) {
            currentTripStateIdVar(tripStateId);
        }
        else {
            const results = yield initializeApollo().query({
                query: SEARCH_PARAMS_QUERY,
                variables: { tripStateId },
            });
            if (results.data.getTripState.tripStateId)
                currentTripStateIdVar(tripStateId);
        }
    }
    catch (error) {
        yield newSearch(newSearchSideEffect, query);
    }
});
