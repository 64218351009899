const defaultContent = {
    title: 'An error has occurred.',
    subtitle: 'But worry not. We have a number of options to help you get back on track.',
    backButton: 'Go back',
    homeButton: 'Take me home',
    supportTitle: 'Still encountering an error?',
    supportText: 'Contact our support team on',
    supportTel: '020 8974 7200',
};
export const defaultErrorPageContent = {
    404: Object.assign(Object.assign({}, defaultContent), { title: `Sorry, we can't seem to find that page` }),
    500: Object.assign({}, defaultContent),
};
export function getErrorPageContent(statusCode, contentOverrides = {}) {
    switch (statusCode) {
        case 404:
            return Object.assign(Object.assign({}, defaultErrorPageContent[404]), contentOverrides[404]);
        default:
            return Object.assign(Object.assign({}, defaultErrorPageContent[500]), contentOverrides[500]);
    }
}
