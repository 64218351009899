import { gql, makeVar } from '@apollo/client';
export const defaultAvailableFlightFilters = [];
export const currentAvailableFlightFiltersVar = makeVar(defaultAvailableFlightFilters);
export const currentFlightFilterSelectionsVar = makeVar({});
export const currentFlightFiltersCachePolicy = {
    currentAvailableFlightFilters: {
        read() {
            return currentAvailableFlightFiltersVar();
        },
    },
    currentFlightFilterSelections: {
        read() {
            return currentFlightFilterSelectionsVar();
        },
    },
};
export const currentFlightFiltersTypeDefs = gql `
    extend type Query {
        currentAvailableFlightFilters: [FilterGroup!]
        currentFlightFilterSelectionsVar: FlightFilters
    }
`;
