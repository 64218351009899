import { FlightSortCriterion, SortCriterion } from '../contracts/types';
import { currentTripStateIdVar } from '../client/search/currentTripState';
import { currentRoutingTabVar } from '../client/routing-tabs/currentRoutingTab';
import { currentHotelFilterSelectionsVar } from '../client/filter/currentHotelFilters';
import { currentDeepLinkPriceTypeVar, currentFlightFilterSelectionsVar, currentFlightSortCriterionVar, currentHotelSortCriterionVar, currentTransferAirportVar, } from '../client';
import { setSearchState } from '../client/search/setSearchState';
import { FilterProductEnum, isCriteriaDifferent, LocalStorageEnum, PriceDisplayMode, PriceType, QueryParamsEnum, validateSearchQueryParams, } from '../util';
import { extractFilters } from '../util/filters/extractFilters';
// Set up the initial app state from the query
export function initialAppState(query, newSearchSideEffect, priceDisplayModeToggleRouting) {
    var _a, _b, _c, _d, _e, _f;
    if (!query)
        return;
    const queryTripId = (_a = query[QueryParamsEnum.TRIP_STATE_ID]) === null || _a === void 0 ? void 0 : _a.toString();
    const queryPriceDisplayMode = query[QueryParamsEnum.PRICE_DISPLAY_MODE];
    // If we have a pre-existing query param for price display only then change it and update it
    // This is to prevent query param trailing on homepage and landing pages.
    // give priority to the value in local storage if present else use the one in query param if its valid mode.
    if (queryPriceDisplayMode) {
        const priceDisplayMode = typeof window !== 'undefined' && (window === null || window === void 0 ? void 0 : window.localStorage.getItem(LocalStorageEnum.PRICE_DISPLAY_MODE));
        const targetDisplayMode = priceDisplayMode || queryPriceDisplayMode;
        if (targetDisplayMode === PriceDisplayMode.PerPersonPrice ||
            targetDisplayMode === PriceDisplayMode.TotalPrice) {
            priceDisplayModeToggleRouting(targetDisplayMode);
            (_b = window === null || window === void 0 ? void 0 : window.localStorage) === null || _b === void 0 ? void 0 : _b.setItem(LocalStorageEnum.PRICE_DISPLAY_MODE, targetDisplayMode);
        }
    }
    const currentTripId = currentTripStateIdVar();
    const isDeepLink = !queryTripId && validateSearchQueryParams(query);
    const isNewTripId = queryTripId && currentTripId !== queryTripId;
    if (isDeepLink || isNewTripId) {
        setSearchState(queryTripId, query, newSearchSideEffect);
    }
    const queryHotelFilterCriteria = extractFilters(FilterProductEnum.HOTEL, query);
    const currentFilterCriteria = currentHotelFilterSelectionsVar();
    if (isCriteriaDifferent(queryHotelFilterCriteria, currentFilterCriteria)) {
        currentHotelFilterSelectionsVar(queryHotelFilterCriteria);
    }
    const queryFlightFilterCriteria = extractFilters(FilterProductEnum.FLIGHT, query);
    const currentFlightFilterCriteria = currentFlightFilterSelectionsVar();
    if (isCriteriaDifferent(queryFlightFilterCriteria, currentFlightFilterCriteria)) {
        currentFlightFilterSelectionsVar(queryFlightFilterCriteria);
    }
    const querySortCriterion = (_c = query[QueryParamsEnum.SORT]) === null || _c === void 0 ? void 0 : _c.toString();
    const currentSortCriterion = currentHotelSortCriterionVar();
    if (!querySortCriterion) {
        currentHotelSortCriterionVar(SortCriterion.RecommendedHighestFirst);
    }
    if (querySortCriterion &&
        querySortCriterion !== currentSortCriterion &&
        Object.values(SortCriterion).includes(querySortCriterion)) {
        currentHotelSortCriterionVar(querySortCriterion);
    }
    const queryFlightSortCriterion = (_d = query[QueryParamsEnum.FSORT]) === null || _d === void 0 ? void 0 : _d.toString();
    const currentFlightSortCriterion = currentFlightSortCriterionVar();
    if (!queryFlightSortCriterion) {
        currentFlightSortCriterionVar(FlightSortCriterion.RecommendedHighestFirst);
    }
    if (queryFlightSortCriterion &&
        queryFlightSortCriterion !== currentFlightSortCriterion &&
        Object.values(FlightSortCriterion).includes(queryFlightSortCriterion)) {
        currentFlightSortCriterionVar(queryFlightSortCriterion);
    }
    if (query.idString && query.slug) {
        const routeSlug = query.slug.length && query.slug[1] ? query.slug[1] : '';
        currentRoutingTabVar(routeSlug);
    }
    const queryDeepLinkPriceType = (_e = query[QueryParamsEnum.DEEP_LINK_PRICE_TYPE]) === null || _e === void 0 ? void 0 : _e.toString();
    const currentDeepLinkPriceType = currentDeepLinkPriceTypeVar();
    if (queryDeepLinkPriceType &&
        queryDeepLinkPriceType !== currentDeepLinkPriceType &&
        Object.values(PriceType).includes(queryDeepLinkPriceType)) {
        currentDeepLinkPriceTypeVar(queryDeepLinkPriceType);
    }
    const airport = (_f = query[QueryParamsEnum.AIRPORT]) === null || _f === void 0 ? void 0 : _f.toString();
    const currentTransferAirport = currentTransferAirportVar();
    if (airport && airport !== currentTransferAirport) {
        currentTransferAirportVar(airport);
    }
}
