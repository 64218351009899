import { gql, useQuery } from '@apollo/client';
import { currentModalVar } from './currentModal';
export function useModal() {
    const MODAL_QUERY = gql `
        query Modal {
            currentModal @client {
                isOpen
                heading
                subheading
                body
            }
        }
    `;
    const currentModalState = useQuery(MODAL_QUERY, { ssr: false });
    function setCurrentModalState({ isOpen = true, heading, subheading, body }) {
        currentModalVar({
            isOpen,
            heading: heading || '',
            subheading: subheading || '',
            body: body || '',
        });
    }
    // Set the modal to closed but retain the contents.
    // We don't want to empty the contents until the close animation has exited,
    // otherwise we see the modal collapse before it animates.
    function closeModal() {
        var _a;
        currentModalVar(Object.assign(Object.assign({}, (_a = currentModalState.data) === null || _a === void 0 ? void 0 : _a.currentModal), { isOpen: false }));
    }
    // Empty the modal contents - call this when the close animation has exited.
    function emptyModal() {
        currentModalVar({
            isOpen: false,
            heading: '',
            subheading: '',
            body: '',
        });
    }
    return [currentModalState, setCurrentModalState, closeModal, emptyModal];
}
