import { QueryParamsEnum } from '@honeycomb/data';
import { QueryParamManager } from '../utils/QueryParamManager';
import { router } from '../utils/router';
export function accountTripsRoute() {
    const paramManager = new QueryParamManager('/account/trips/', router.currentParams());
    paramManager.removeMultiple([
        QueryParamsEnum.CHECK_IN_DATE,
        QueryParamsEnum.AIRPORT,
        QueryParamsEnum.AIRPORT_TITLE,
        QueryParamsEnum.RESORT_ID,
        QueryParamsEnum.RESORT_TITLE,
        QueryParamsEnum.ADULTS,
        QueryParamsEnum.CHILD_AGES,
        QueryParamsEnum.TRIP_TYPE,
    ]);
    return [paramManager.toString(), undefined, { shallow: false }];
}
export function accountTripsRouting() {
    router.push(undefined, accountTripsRoute);
}
