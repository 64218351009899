import { gql, makeVar } from '@apollo/client';
export const currentTransferAirportVar = makeVar('');
export const currentTransferAirportCachePolicy = {
    currentTransferAirport: {
        read() {
            return currentTransferAirportVar();
        },
    },
};
export const currentTransferAirportTypeDefs = gql `
    extend type Query {
        currentTransferAirportVar: string
    }
`;
