import * as React from 'react';
import { useRouter } from 'next/router';
import { useGlobalContext, parseBoolean, useAirportAutocompleterResults, } from '@honeycomb/data';
import dynamic from 'next/dynamic';
import { getPopUpContent } from '../../utils/getPopUpDetails';
import { PopUp } from '../../features/content/PopUp';
import { getPromoBarContent } from '../../utils/getPromoBarDetails';
import { PromoBar } from '../../features/content/PromoBar';
import { useStoredRecentViewed } from '../../utils/recentView';
import { DefaultMasthead } from '../DefaultMasthead';
export function Header({ brandLogo, masthead }) {
    var _a;
    const { pathname, query } = useRouter();
    const { globalContents, configSettings: { HCEnableRecentView, HCPromoBarExcludedUrls, HCPromoBarCategoryUrls, HCPopUpExcludedUrls, HCPopUpCategoryUrls, }, } = useGlobalContext();
    const { PROMOBAR, POPUP } = globalContents;
    const { content: promoBar } = PROMOBAR || {};
    const promoBarItem = getPromoBarContent(HCPromoBarExcludedUrls, HCPromoBarCategoryUrls, pathname, query, (promoBar === null || promoBar === void 0 ? void 0 : promoBar.__typename) === 'PromoBar' ? promoBar : undefined);
    const { content } = POPUP || {};
    const popUpItem = content
        ? getPopUpContent(HCPopUpExcludedUrls, HCPopUpCategoryUrls, pathname, content)
        : undefined;
    const isHomePage = pathname === '/';
    const shouldShowRecentlyViewed = isHomePage && parseBoolean(HCEnableRecentView);
    const [fetchMoreAirports, airportAutocompleterResults] = useAirportAutocompleterResults();
    React.useEffect(() => {
        if (shouldShowRecentlyViewed) {
            fetchMoreAirports({
                searchTerm: '',
            });
        }
    }, [fetchMoreAirports, shouldShowRecentlyViewed]);
    const [RecentViewedData, clearRecentViewStorage] = useStoredRecentViewed((_a = airportAutocompleterResults === null || airportAutocompleterResults === void 0 ? void 0 : airportAutocompleterResults.data) === null || _a === void 0 ? void 0 : _a.airportAutocompleterResults);
    const RecentView = shouldShowRecentlyViewed &&
        dynamic(() => import('../../features/recently-viewed/RecentlyViewed').then((module) => module.RecentlyViewed), { ssr: false });
    return (React.createElement("header", null,
        promoBarItem && React.createElement(PromoBar, { promoBarItem: promoBarItem }),
        popUpItem && React.createElement(PopUp, { popUpItem: popUpItem }),
        masthead || React.createElement(DefaultMasthead, { brandLogo: brandLogo }),
        RecentView && RecentViewedData && (React.createElement(RecentView, { recentViewed: RecentViewedData, isStickyAvailable: !!promoBarItem, onClose: clearRecentViewStorage }))));
}
