import { gql, makeVar } from '@apollo/client';
export const currentRoutingTabVar = makeVar('');
export const currentRoutingTabIdCachePolicy = {
    currentRoutingTabId: {
        read() {
            return currentRoutingTabVar();
        },
    },
};
export const currentRoutingTabIdTypeDefs = gql `
    extend type Query {
        currentRoutingTabId: string
    }
`;
