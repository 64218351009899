import { Styler, StylerProps } from '@honeycomb/ui-core';
import * as React from 'react';

export function DnataTravelLogoStacked(props: StylerProps): React.JSX.Element {
    return (
        <Styler component="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201 120" {...props}>
            <g clipPath="url(#clip0_1492_11140)">
                <path
                    d="M13.5892 113.588C10.2135 113.588 9.24624 110.92 9.24624 108.188V87.2168H20.885V80.9747H9.24624V68.6826H2.33154V109.598C2.33154 113.479 4.22116 120 12.5995 120C15.7414 120 19.1267 119.391 21.2917 118.402L19.3381 112.233C16.747 113.265 14.8895 113.588 13.5892 113.588Z"
                    fill="#5BBA47"
                />
                <path d="M174.811 68.6826H167.894V119.007H174.811V68.6826Z" fill="#5BBA47" />
                <path
                    d="M48.8931 86.4674C47.6952 86.3873 46.6544 86.4674 45.1363 86.4674C40.6588 86.4674 34.2598 89.3434 34.2598 95.9763V119.126H27.3867V80.9619H33.7826V87.156C35.3775 82.3615 41.8535 80.1548 46.0939 80.1548H48.8899V86.4674H48.8931Z"
                    fill="#5BBA47"
                />
                <path
                    d="M85.34 92.5174C85.34 85.9838 79.6103 80.1932 67.552 80.1932C59.8847 80.1932 52.3487 84.4337 52.3487 84.4337L55.308 89.4972C55.308 89.4972 60.2626 86.2336 67.52 86.2336C77.6887 86.2336 78.5918 90.884 78.6015 93.065V93.6447C78.6015 93.6447 70.8701 94.3365 66.2966 94.7368C58.9495 95.403 49.479 98.7723 49.479 107.903C49.479 115.859 57.1784 119.984 65.7553 119.984C72.4426 119.984 78.8353 116.586 78.8353 116.586V119.074H85.3432V92.5206L85.34 92.5174ZM65.3774 114.206C62.7639 114.206 56.6531 113.181 56.6531 107.029C56.6531 102.446 62.165 100.582 66.7577 100.181C69.9733 99.8964 78.6559 99.1694 78.6559 99.1694C78.6559 99.1694 78.6559 108.397 78.6559 109.191C76.3115 112.454 70.8412 114.203 65.3774 114.203"
                    fill="#5BBA47"
                />
                <path
                    d="M102.318 119.055L86.9448 80.9523H94.4712L106.469 113.111L118.86 80.9523H126.402L110.639 119.055H102.318Z"
                    fill="#5BBA47"
                />
                <path
                    d="M126.249 100.082C126.249 88.6004 133.08 80.2477 144.325 80.2477C157.363 80.2477 164.15 88.9655 162.244 102.776H133.16C133.16 109.646 138.118 114.171 146.009 114.171C148.271 114.171 151.476 114.68 158.036 111.945L160.146 116.868C157.725 118.347 152.255 119.99 145.382 119.99C134.922 119.99 126.255 112.493 126.255 100.082M133.391 96.8346H155.96C155.505 91.137 152.034 86.0671 144.322 86.0671C137.919 86.0671 133.387 91.374 133.387 96.8346"
                    fill="#5BBA47"
                />
                <path d="M41.7349 13.0959V20.6672L50.2862 13.0959H41.7349Z" fill="#5BBA47" />
                <path
                    d="M34.4166 13.0896H19.7801C9.79716 13.0896 0 20.6576 0 33.5678C0 46.4781 9.46728 54.6546 20.9106 54.6546C33.3885 54.6546 41.7316 44.6909 41.7316 33.2155V21.1893H34.4134V33.2252C34.4134 39.7235 29.6925 47.6695 20.9203 47.6695C13.4899 47.6695 7.41753 41.3537 7.40152 33.5646C7.40152 27.9567 11.3121 19.9786 21.6024 19.9786H33.9522L41.7284 13.0864V0H34.4102V13.0896H34.4166Z"
                    fill="#0090CD"
                />
                <path
                    d="M72.5323 11.6516C65.7457 11.6516 59.3691 15.4724 57.3257 19.2901V13.096H50.2861V53.6586H58.0912V28.53C58.0912 21.3494 65.8802 17.7624 70.8028 17.7624C79.2709 17.7624 80.9971 23.3607 80.9971 29.9872V53.6618H88.4659V29.1385C88.4659 15.0529 80.6224 11.6548 72.5323 11.6548"
                    fill="#0090CD"
                />
                <path
                    d="M151.374 47.8232C147.78 47.8232 146.746 44.9792 146.746 42.0679V19.7384H159.14V13.0864H146.746V0H139.383V43.5732C139.383 47.7047 141.397 54.6578 150.32 54.6578C153.667 54.6578 157.27 53.9981 159.576 52.9508L157.494 46.3852C154.734 47.4837 152.757 47.8264 151.371 47.8264"
                    fill="#0D91CC"
                />
                <path
                    d="M151.374 47.8232C147.78 47.8232 146.746 44.9792 146.746 42.0679V19.7384H159.14V13.0864H146.746V0H139.383V43.5732C139.383 47.7047 141.397 54.6578 150.32 54.6578C153.667 54.6578 157.27 53.9981 159.576 52.9508L157.494 46.3852C154.734 47.4837 152.757 47.8264 151.371 47.8264"
                    fill="#0090CD"
                />
                <path
                    d="M182.076 12.2665C182.076 12.2665 182.066 12.2601 182.053 12.2665C173.627 12.2665 165.863 16.7855 165.863 16.7855L169.002 22.1821C169.002 22.1821 174.29 18.7007 182.021 18.7007C192.862 18.7007 193.801 23.6554 193.836 25.9742V26.5955C193.836 26.5955 185.592 27.3033 180.714 27.7613C173.732 28.405 169.249 30.5316 166.526 33.1771C163.798 35.8065 162.805 38.9708 162.805 41.69V41.7893C162.805 50.2989 171.004 54.6514 180.138 54.6514C187.28 54.6514 194.083 51.0291 194.083 51.0291V53.681H201.007V25.3945C201.007 19.463 196.023 12.2697 182.079 12.2697M193.881 43.16C191.367 46.635 185.554 48.499 179.731 48.499C176.961 48.499 170.437 47.4036 170.437 40.8573C170.437 35.9699 176.327 33.981 181.198 33.555C184.622 33.2508 193.881 32.4789 193.881 32.4789V43.16Z"
                    fill="#0090CD"
                />
                <path
                    d="M132.202 53.6778L132.196 25.3913C132.196 18.4349 126.095 12.2665 113.249 12.2665C105.088 12.2665 97.062 16.7855 97.062 16.7855L100.21 22.1789C100.21 22.1789 105.485 18.7008 113.213 18.7008C124.045 18.7008 125.009 23.6554 125.015 25.9742V26.5955C125.015 26.5955 116.784 27.3257 111.91 27.7613C104.086 28.4659 94.0034 32.0562 94.0034 41.7861C94.0034 50.2573 102.202 54.6482 111.337 54.6482C118.466 54.6482 125.272 51.026 125.272 51.026V53.6778H132.202ZM110.933 48.499C108.153 48.499 101.645 47.4037 101.645 40.8573C101.645 35.9699 107.516 33.981 112.403 33.5518C115.83 33.2508 125.076 32.4757 125.076 32.4757C125.076 32.4757 125.083 42.3145 125.083 43.1568C122.585 46.6318 116.752 48.4958 110.933 48.4958"
                    fill="#0090CD"
                />
            </g>
            <defs>
                <clipPath id="clip0_1492_11140">
                    <rect width="201.004" height="120" fill="white" />
                </clipPath>
            </defs>
        </Styler>
    );
}
