import { BackgroundColourName } from '@honeycomb/data';
/**
 * Maps the CMS background color property to the appropriate Honeycomb theme color.
 *
 * @param {BackgroundColourName} [color] - The background color to be mapped.
 * @returns {ColorOption} - The corresponding theme color value as a string. If the input color does not match any predefined background colors, it returns `undefined`.
 */
export function getBackgroundColor(color) {
    switch (color) {
        case BackgroundColourName.Primary:
            return 'primary.base';
        case BackgroundColourName.Secondary:
            return 'secondary.base';
        case BackgroundColourName.Accent:
            return 'accent.base';
        case BackgroundColourName.Offset:
            return 'background.offset';
        case BackgroundColourName.White:
            return 'common.white';
        case BackgroundColourName.Black:
            return 'common.black';
        default:
            return undefined;
    }
}
