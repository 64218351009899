import { persistTrackingData } from '../util';
import { initDataLayer } from '../util/initDataLayer';
import { isBrowser } from '../util/isBrowser';
export function pushVisitData(userId) {
    if (!isBrowser())
        return;
    initDataLayer();
    const message = {
        userId,
    };
    window.dataLayer.push(message);
    persistTrackingData(message);
}
