import { TripType } from '@honeycomb/data';
import { DateFormats, getFormattedDate, toUTCDate } from './date-utils';
export const formatDate = (date, includeYear, includeTime) => {
    // Should this be config determined or local locale?
    return date.toLocaleDateString('en-US', {
        year: includeYear ? 'numeric' : undefined,
        month: 'short',
        day: 'numeric',
        timeZone: includeTime ? 'numeric' : undefined,
    });
};
export const formatDateMonthYear = (date) => {
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
    });
};
export const formatDateTime = (date) => {
    // Should this be config determined or local locale?
    return date.toLocaleTimeString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });
};
export const getFormattedStartDate = (pricingSummary, hotelSearch) => {
    const { tripType, startDate, duration, flexibleDays, wholeMonthStartDate } = hotelSearch;
    const { checkInDate, departureDate } = pricingSummary;
    const startDateFormat = (startDate || wholeMonthStartDate) &&
        getFormattedDate(DateFormats.Shortdaydateyear, startDate || wholeMonthStartDate || '');
    let departureDateArr = [];
    if (departureDate) {
        departureDateArr = departureDate.split(' ');
    }
    let departureDateFormat = '';
    if (departureDateArr === null || departureDateArr === void 0 ? void 0 : departureDateArr.length) {
        const departureDateIso = String(toUTCDate(new Date(`${departureDateArr[0].slice(0, -2)}-${departureDateArr[1]}-${departureDateArr[2]}`)));
        departureDateFormat = String(getFormattedDate(DateFormats.Shortdaydateyear, departureDateIso));
    }
    let formattedStartDate = '';
    if (flexibleDays || duration) {
        formattedStartDate =
            tripType === TripType.Holiday
                ? departureDateFormat
                : String(checkInDate ? getFormattedDate(DateFormats.Shortdaydateyear, checkInDate) : '');
    }
    else {
        formattedStartDate = String(startDateFormat);
    }
    return formattedStartDate || String(startDateFormat);
};
