import { useGlobalContext } from '@honeycomb/data';
import Head from 'next/head';
import React from 'react';
export function GoogleTagManager() {
    const { configSettings: { HCGoogleTagManagerId } } = useGlobalContext();
    if (!HCGoogleTagManagerId)
        return null;
    return (React.createElement(Head, null,
        React.createElement("script", { dangerouslySetInnerHTML: {
                __html: `
                    window.dataLayer = window.dataLayer || []; var persistedDataLayer = 
                    window.sessionStorage.getItem('trackingCache'); if(persistedDataLayer){
                    persistedDataLayer = JSON.parse(persistedDataLayer); persistedDataLayer.event =
                    'initialiseDataLayer';dataLayer.push(persistedDataLayer)};
                `,
            } }),
        React.createElement("script", { dangerouslySetInnerHTML: {
                __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${HCGoogleTagManagerId}');
                `,
            } })));
}
