import { gql, makeVar } from '@apollo/client';
import { FlightJourneyType, TripType } from '../../contracts/types';
import { Cabin } from '../../util';
export const initialSearchParameters = {
    startDate: '',
    endDate: '',
    destinationIds: null,
    destinationTitle: '',
    hotelIds: null,
    hotelTitle: '',
    numberOfNights: 0,
    isNumberOfNightsAdjusted: false,
    rooms: [{ adults: 2, childAges: [] }],
    departureAirports: [],
    tripType: TripType.Holiday,
    arrivalAirports: [],
    tripStateId: '',
    adults: 2,
    childAges: [],
    isArrivalAnywhere: false,
    isDirectOnly: false,
    cabin: Cabin.Economy,
    flexibleDays: 0,
    pickUpPoint: {},
    dropOffPoint: {},
    flightJourneyType: FlightJourneyType.Return,
};
export const currentSearchParameters = makeVar(initialSearchParameters);
export const currentSearchParametersCachePolicy = {
    currentSearchParameters: {
        read() {
            return currentSearchParameters();
        },
    },
};
export const currentSearchParametersTypeDefs = gql `
    extend type Query {
        currentSearchParameters: SearchParameters
    }
`;
