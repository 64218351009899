export var OperationType;
(function (OperationType) {
    OperationType["query"] = "query";
    OperationType["mutation"] = "mutation";
})(OperationType || (OperationType = {}));
export var PriceDisplayMode;
(function (PriceDisplayMode) {
    PriceDisplayMode["TotalPrice"] = "1";
    PriceDisplayMode["PerPersonPrice"] = "2";
})(PriceDisplayMode || (PriceDisplayMode = {}));
