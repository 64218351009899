import { gql, makeVar } from '@apollo/client';
export const currentModalVar = makeVar({
    isOpen: false,
    heading: '',
    subheading: '',
    body: '',
});
export const currentModalCachePolicy = {
    currentModal: {
        read() {
            return currentModalVar();
        },
    },
};
export const currentModalTypeDefs = gql `
    extend type Query {
        currentModal: CurrentModal
    }

    type CurrentModal {
        isOpen: Boolean!
        heading: String
        subheading: String
        body: String
    }
`;
