import { FallbackProps } from 'react-error-boundary';
import React from 'react';
import { ErrorProps, UnexpectedError } from '@honeycomb/ui';
import { errorPageContent } from './errorContent';

// Function that takes a correlationId and returns a render function for the ErrorBoundary
export function errorFallback(
    correlationId?: string
): (
    props: FallbackProps
) => React.ReactElement<unknown, string | React.FunctionComponent | typeof React.Component> | null {
    const errorFunction = ({ error, resetErrorBoundary }: FallbackProps) => (
        <UnexpectedError
            correlationId={correlationId}
            error={error as ErrorProps}
            resetErrorBoundary={resetErrorBoundary}
            contentOverrides={errorPageContent}
        />
    );
    return errorFunction;
}
