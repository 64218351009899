/* eslint-disable no-console */
import React from 'react';
import { resourceStringsRegistry, ResourceStringsKey } from './resourceStrings';
import { ConfigSettingsKey, configSettingsRegistry } from './configSettings';
import { globalContentsRegistry } from './globalContents';
export function CreateInitialResourceStringsObject() {
    const obj = {};
    Object.values(ResourceStringsKey).forEach((v) => {
        obj[v] = '';
    });
    return obj;
}
export function CreateInitialConfigSettingsObject() {
    const obj = {};
    Object.values(ConfigSettingsKey).forEach((v) => {
        obj[v] = '';
    });
    return obj;
}
export const GlobalContext = React.createContext({
    resourceStrings: CreateInitialResourceStringsObject(),
    configSettings: CreateInitialConfigSettingsObject(),
    globalContents: {},
    currentContext: {},
    deviceInfo: {},
});
export function GlobalProvider(props) {
    const { resourceStrings, configSettings, children, globalContents, currentContext, deviceInfo } = props;
    // This comnponent should only ever render once on page load so no need to memoize this value
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    let context = {
        resourceStrings: CreateInitialResourceStringsObject(),
        configSettings: CreateInitialConfigSettingsObject(),
        globalContents: {},
        currentContext,
        deviceInfo: {},
    };
    if ((configSettings === null || configSettings === void 0 ? void 0 : configSettings.length) > 0 || (resourceStrings === null || resourceStrings === void 0 ? void 0 : resourceStrings.length) > 0 || (globalContents === null || globalContents === void 0 ? void 0 : globalContents.length) > 0) {
        const resourceStringLookup = {};
        resourceStrings === null || resourceStrings === void 0 ? void 0 : resourceStrings.forEach((resourceString) => {
            resourceStringLookup[resourceString === null || resourceString === void 0 ? void 0 : resourceString.key] = resourceString.value || '';
        });
        const configSettingLookup = {};
        configSettings === null || configSettings === void 0 ? void 0 : configSettings.forEach((configSetting) => {
            configSettingLookup[configSetting === null || configSetting === void 0 ? void 0 : configSetting.key] = configSetting.value || '';
        });
        const globalContentLookup = {};
        globalContents === null || globalContents === void 0 ? void 0 : globalContents.forEach((globalContent) => {
            globalContentLookup[globalContent === null || globalContent === void 0 ? void 0 : globalContent.key] = globalContent;
        });
        resourceStringsRegistry.forEach((resourceStringKey) => {
            const matchedResourceString = resourceStringLookup[resourceStringKey.toString()];
            if (matchedResourceString !== undefined) {
                context.resourceStrings[resourceStringKey] = matchedResourceString;
            }
            else {
                console.warn(`Resource string '${resourceStringKey}' was not found. Make sure it is added to derwent.io and/or the mocks`);
                context.resourceStrings[resourceStringKey] = resourceStringKey;
            }
        });
        configSettingsRegistry.forEach((configSettingsKey) => {
            const matchedConfigSetting = configSettingLookup[configSettingsKey.toString()];
            if (matchedConfigSetting !== undefined) {
                context.configSettings[configSettingsKey] = matchedConfigSetting;
            }
            else {
                console.warn(`Config setting '${configSettingsKey}' was not found. Make sure it is added to derwent.io and/or the mocks`);
            }
        });
        globalContentsRegistry.forEach((globalContentKey) => {
            const matchedContent = globalContentLookup[globalContentKey];
            if (matchedContent) {
                context.globalContents[globalContentKey] = matchedContent;
            }
            else {
                console.warn(`Global content '${globalContentKey}' was not found. Make sure it is added to Umbraco and/or the mocks`);
            }
        });
        if (deviceInfo) {
            context.deviceInfo = deviceInfo;
        }
        if (typeof window !== 'undefined') {
            sessionStorage.setItem('context', JSON.stringify(context));
        }
    }
    else if (typeof window !== 'undefined') {
        const value = sessionStorage.getItem('context');
        if (value) {
            context = JSON.parse(value);
        }
    }
    return React.createElement(GlobalContext.Provider, { value: context }, children);
}
export function useGlobalContext() {
    return React.useContext(GlobalContext);
}
