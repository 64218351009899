import { ConfigSettingsKey } from '../server/global';
import { QueryParamsEnum } from './enums';
const registeredFeatureFlags = new Set();
export function setFeatureFlags(configSettings, query) {
    if (!configSettings || !query)
        return;
    registeredFeatureFlags.clear();
    const experimentalFeatures = configSettings.find((setting) => setting.key === ConfigSettingsKey.HcExperimentalFeatures);
    const featureFlags = query === null || query === void 0 ? void 0 : query[QueryParamsEnum.FEATURE_FLAGS];
    if ((experimentalFeatures === null || experimentalFeatures === void 0 ? void 0 : experimentalFeatures.value) === 'false' || !featureFlags)
        return;
    featureFlags.split('|').forEach((item) => registeredFeatureFlags.add(item));
}
export function hasFeatureFlag(feature) {
    return registeredFeatureFlags.has(feature);
}
