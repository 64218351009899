import { gql, makeVar } from '@apollo/client';
export const defaultCurrentRooms = {
    rooms: [],
    selectedRoomIndex: 0,
    showPerPersonPrices: false,
    soldOut: false,
};
export const currentRoomsVar = makeVar(defaultCurrentRooms);
export const currentRoomsCachePolicy = {
    currentRooms: {
        read() {
            return currentRoomsVar();
        },
    },
};
export const currentRoomsTypeDefs = gql `
    extend type Query {
        currentRooms: CurrentRooms
    }

    type EnhancedBoardOption {
        boardOptionId: String!
        isConfirmed: Boolean!
        price: Float!
        pricePerPerson: Float!
        wasPrice: Float
        wasPricePerPerson: Float
        roomTitle: String!
        boardTypeDescription: String!
        isSelected: Boolean!
        isPreviouslySelected: Boolean
    }

    type CurrentRooms {
        rooms: [EnhancedBoardOption!]
        selectedRoomIndex: Int!
        showPerPersonPrices: Boolean
        soldOut: Boolean
    }
`;
