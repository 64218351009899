import { expandSearchParams } from './expandSearchParams';
import { TripType } from '../../contracts/types';
const isValidDestinationOrHotelId = (id) => Boolean(id && Number.isFinite(id));
const isValidFlexibleDestination = (destinations) => Boolean(destinations === null || destinations === void 0 ? void 0 : destinations.some((el) => el && el.id && el.type));
export const validateSearchQueryParams = (query) => {
    var _a, _b, _c, _d;
    const querySearchParameters = expandSearchParams(query);
    const tripType = querySearchParameters === null || querySearchParameters === void 0 ? void 0 : querySearchParameters.tripType;
    if (tripType === TripType.Transfer) {
        return Boolean(querySearchParameters &&
            (querySearchParameters.startDate || querySearchParameters.endDate) &&
            querySearchParameters.pickUpPoint &&
            querySearchParameters.dropOffPoint &&
            querySearchParameters.adults);
    }
    if (tripType === TripType.Flight) {
        return Boolean(querySearchParameters &&
            querySearchParameters.startDate &&
            querySearchParameters.flightJourneyType &&
            ((_a = querySearchParameters.departureAirports) === null || _a === void 0 ? void 0 : _a.length) &&
            ((_b = querySearchParameters.arrivalAirports) === null || _b === void 0 ? void 0 : _b.length) &&
            querySearchParameters.adults);
    }
    return Boolean(querySearchParameters &&
        querySearchParameters.startDate &&
        querySearchParameters.endDate &&
        (isValidDestinationOrHotelId(Number((_c = querySearchParameters.destinationIds) === null || _c === void 0 ? void 0 : _c[0])) ||
            isValidDestinationOrHotelId(Number((_d = querySearchParameters.hotelIds) === null || _d === void 0 ? void 0 : _d[0])) ||
            isValidFlexibleDestination(querySearchParameters.destinations)) &&
        querySearchParameters.rooms &&
        (querySearchParameters.tripType !== TripType.Holiday || querySearchParameters.departureAirports));
};
