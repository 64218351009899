import { gql, makeVar } from '@apollo/client';
export const defaultAvailableHotelFilters = [];
export const currentAvailableHotelFiltersVar = makeVar(defaultAvailableHotelFilters);
export const currentHotelFilterSelectionsVar = makeVar({});
export const currentHotelFiltersCachePolicy = {
    currentAvailableHotelFilters: {
        read() {
            return currentAvailableHotelFiltersVar();
        },
    },
    currentHotelFilterSelections: {
        read() {
            return currentHotelFilterSelectionsVar();
        },
    },
};
export const currentHotelFiltersTypeDefs = gql `
    extend type Query {
        currentAvailableHotelFilters: [FilterGroup!]
        currentHotelFilterSelectionsVar: HotelFilters
    }
`;
