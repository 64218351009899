import { DateSearchType, TripType } from '../../contracts/types';
import { QueryParamsEnum } from '../enums';
export const flattenSearchParams = ({ startDate, endDate, destinations, destinationIds, hotelIds, rooms, tripType, departureAirports, flightJourneyType, adults, arrivalAirports, childAges, cabin, wholeMonthEndDate, wholeMonthStartDate, duration, flexibleDays, dateSearchType, pickUpPoint, dropOffPoint, }) => {
    const isHotelOrHoliday = tripType === TripType.Holiday || tripType === TripType.Hotel;
    const hasNoDestination = destinationIds && (destinationIds === null || destinationIds === void 0 ? void 0 : destinationIds.length) < 1 && hotelIds && (hotelIds === null || hotelIds === void 0 ? void 0 : hotelIds.length) < 1;
    if (isHotelOrHoliday && !duration && (!startDate || !endDate || hasNoDestination || !rooms)) {
        return {};
    }
    if (isHotelOrHoliday && duration && (!wholeMonthStartDate || !wholeMonthEndDate || hasNoDestination || !rooms)) {
        return {};
    }
    if (tripType === TripType.Flight && !startDate) {
        return {};
    }
    if (tripType === TripType.Transfer && (!pickUpPoint || !dropOffPoint || (!startDate && !endDate))) {
        return {};
    }
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ [QueryParamsEnum.CHECK_IN_DATE]: startDate.substring(0, 10) || (wholeMonthStartDate && wholeMonthStartDate.substring(0, 10)) || '', [QueryParamsEnum.CHECK_OUT_DATE]: endDate.substring(0, 10) || (wholeMonthEndDate && wholeMonthEndDate.substring(0, 10)) || '', [QueryParamsEnum.DESTINATIONS]: destinations === null || destinations === void 0 ? void 0 : destinations.map((d) => `${d === null || d === void 0 ? void 0 : d.id}:${d === null || d === void 0 ? void 0 : d.type}`).join('-'), [QueryParamsEnum.DESTINATION_IDS]: destinationIds === null || destinationIds === void 0 ? void 0 : destinationIds.map((destId) => destinationIds.length > 1 ? `${destId.toString()}|` : destId.toString()), [QueryParamsEnum.HOTEL_IDS]: hotelIds === null || hotelIds === void 0 ? void 0 : hotelIds.map((hid) => hotelIds.length > 1 ? `${hid.toString()}` : hid.toString()), [QueryParamsEnum.TRIP_TYPE]: tripType === null || tripType === void 0 ? void 0 : tripType.toString() }, rooms.reduce((acc, room, index) => (Object.assign(Object.assign({}, acc), { [`${QueryParamsEnum.ROOMS}.${index}.${QueryParamsEnum.ADULTS}`]: room.adults.toString(), [`${QueryParamsEnum.ROOMS}.${index}.${QueryParamsEnum.CHILD_AGES}`]: room.childAges.length > 0 && room.childAges.map((age) => age.toString()) })), {})), (departureAirports && departureAirports.length > 0
        ? { [QueryParamsEnum.DEPARTURE_AIRPORTS]: departureAirports === null || departureAirports === void 0 ? void 0 : departureAirports.map((air) => air.toString()) }
        : {})), (tripType === TripType.Flight && flightJourneyType
        ? { [QueryParamsEnum.FLIGHT_JOURNEY_TYPE]: flightJourneyType === null || flightJourneyType === void 0 ? void 0 : flightJourneyType.toString() }
        : {})), { [QueryParamsEnum.ADULTS]: adults === null || adults === void 0 ? void 0 : adults.toString(), [QueryParamsEnum.CHILD_AGES]: childAges === null || childAges === void 0 ? void 0 : childAges.map((age) => age.toString()) }), (arrivalAirports && arrivalAirports.length > 0
        ? { [QueryParamsEnum.ARRIVAL_AIRPORT]: arrivalAirports === null || arrivalAirports === void 0 ? void 0 : arrivalAirports.map((air) => air.toString()) }
        : {})), (((tripType === null || tripType === void 0 ? void 0 : tripType.toString()) === TripType.Holiday || (tripType === null || tripType === void 0 ? void 0 : tripType.toString()) === TripType.Flight) &&
        cabin &&
        Number.isSafeInteger(cabin)
        ? { [QueryParamsEnum.CABIN]: cabin.toString() }
        : {})), (duration && Number.isSafeInteger(duration) ? { [QueryParamsEnum.DURATION]: duration.toString() } : {})), (flexibleDays && Number.isSafeInteger(flexibleDays)
        ? { [QueryParamsEnum.FLEXIBLE_DAYS]: flexibleDays.toString() }
        : {})), { [QueryParamsEnum.DATE_SEARCH_TYPE]: dateSearchType || DateSearchType.ChooseDate, [QueryParamsEnum.PICK_UP_POINT]: pickUpPoint
            ? `${pickUpPoint === null || pickUpPoint === void 0 ? void 0 : pickUpPoint.id}:${pickUpPoint === null || pickUpPoint === void 0 ? void 0 : pickUpPoint.name}:${pickUpPoint === null || pickUpPoint === void 0 ? void 0 : pickUpPoint.type}`
            : '', [QueryParamsEnum.DROP_OFF_POINT]: dropOffPoint
            ? `${dropOffPoint === null || dropOffPoint === void 0 ? void 0 : dropOffPoint.id}:${dropOffPoint === null || dropOffPoint === void 0 ? void 0 : dropOffPoint.name}:${dropOffPoint === null || dropOffPoint === void 0 ? void 0 : dropOffPoint.type}`
            : '' });
};
