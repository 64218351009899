export const isCriteriaDifferent = (a, b) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    if (((_a = a === null || a === void 0 ? void 0 : a.checkboxFilters) === null || _a === void 0 ? void 0 : _a.length) !== ((_b = b === null || b === void 0 ? void 0 : b.checkboxFilters) === null || _b === void 0 ? void 0 : _b.length) ||
        ((_c = a === null || a === void 0 ? void 0 : a.rangeFilters) === null || _c === void 0 ? void 0 : _c.length) !== ((_d = b === null || b === void 0 ? void 0 : b.rangeFilters) === null || _d === void 0 ? void 0 : _d.length) ||
        ((_e = a === null || a === void 0 ? void 0 : a.textFilter) === null || _e === void 0 ? void 0 : _e.length) !== ((_f = b === null || b === void 0 ? void 0 : b.textFilter) === null || _f === void 0 ? void 0 : _f.length))
        return true;
    const checkboxesDifferent = (_g = a === null || a === void 0 ? void 0 : a.checkboxFilters) === null || _g === void 0 ? void 0 : _g.some((criteriaA, criteriaIndex) => {
        var _a;
        const criteriaB = (_a = b === null || b === void 0 ? void 0 : b.checkboxFilters) === null || _a === void 0 ? void 0 : _a[criteriaIndex];
        return (!criteriaB ||
            criteriaA.id !== criteriaB.id ||
            criteriaA.selectedIds.length !== criteriaB.selectedIds.length ||
            criteriaA.selectedIds.some((selectedIdA, index) => selectedIdA !== criteriaB.selectedIds[index]));
    });
    if (checkboxesDifferent)
        return true;
    const textFilterDifferent = (_h = a === null || a === void 0 ? void 0 : a.textFilter) === null || _h === void 0 ? void 0 : _h.some((criteriaA, criteriaIndex) => {
        var _a;
        const criteriaB = (_a = b === null || b === void 0 ? void 0 : b.textFilter) === null || _a === void 0 ? void 0 : _a[criteriaIndex];
        return !criteriaB || criteriaA.id !== (criteriaB === null || criteriaB === void 0 ? void 0 : criteriaB.id) || criteriaA.value !== criteriaB.value;
    });
    if (textFilterDifferent)
        return true;
    return (((_j = a === null || a === void 0 ? void 0 : a.rangeFilters) === null || _j === void 0 ? void 0 : _j.some((criteriaA, criteriaIndex) => {
        var _a;
        const criteriaB = (_a = b === null || b === void 0 ? void 0 : b.rangeFilters) === null || _a === void 0 ? void 0 : _a[criteriaIndex];
        return (!criteriaB ||
            criteriaA.id !== criteriaB.id ||
            criteriaA.lower !== criteriaB.lower ||
            criteriaA.upper !== criteriaB.upper);
    })) || false);
};
