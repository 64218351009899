import { gql } from '@apollo/client';
export const searchParametersFragment = gql `
    fragment SearchParameters on TripState {
        searchParameters {
            tripStateId
            startDate
            endDate
            hotelIds
            hotelTitle
            destinationIds
            destinationTitle
            rooms {
                adults
                childAges
            }
            tripType
            numberOfNights
            isNumberOfNightsAdjusted
            flightDetailsId
            departureAirports
            arrivalAirports
            adults
            childAges
            isArrivalAnywhere
            isDirectOnly
            cabin
            destinations {
                id
                type
                title
                isCached
                subTitle
            }
            flexibleDays
            duration
            wholeMonthStartDate
            wholeMonthEndDate
            dateSearchType
            flightJourneyType
            pickUpPoint {
                id
                name
                type
            }
            dropOffPoint {
                id
                name
                type
            }
        }
    }
`;
export const HOLIDAY_SEARCH_MUTATION = gql `
    ${searchParametersFragment}
    mutation HolidaySearch($request: HolidaySearchRequest!) {
        holidaySearch(request: $request) {
            tripStateId
            ...SearchParameters
        }
    }
`;
export const FLEXIBLE_HOTEL_SEARCH_MUTATION = gql `
    ${searchParametersFragment}
    mutation FlexibleHotelSearchMutation($request: FlexibleHotelSearchRequest!) {
        flexibleHotelSearch(request: $request) {
            tripStateId
            ...SearchParameters
        }
    }
`;
export const FLEXIBLE_HOLIDAY_SEARCH_MUTATION = gql `
    ${searchParametersFragment}
    mutation FlexibleHolidaySearchMutation($request: FlexibleHolidaySearchRequest!) {
        flexibleHolidaySearch(request: $request) {
            tripStateId
            ...SearchParameters
        }
    }
`;
export const HOTEL_SEARCH_MUTATION = gql `
    ${searchParametersFragment}
    mutation HotelSearch($request: HotelSearchRequest!) {
        hotelSearch(request: $request) {
            tripStateId
            ...SearchParameters
        }
    }
`;
export const FLIGHT_SEARCH_MUTATION = gql `
    ${searchParametersFragment}
    mutation FlightSearch($request: FlightSearchRequest!) {
        flightSearch(request: $request) {
            tripStateId
            ...SearchParameters
        }
    }
`;
export const SEARCH_STATE_QUERY = gql `
    ${searchParametersFragment}
    query getTripState($tripStateId: ID!) {
        getTripState(tripStateId: $tripStateId) {
            ...SearchParameters
        }
    }
`;
export const TRANSFER_SEARCH_MUTATION = gql `
    ${searchParametersFragment}
    mutation TransferSearch($request: TransferSearchRequest!) {
        transferSearch(request: $request) {
            tripStateId
            ...SearchParameters
        }
    }
`;
export const SEARCH_PARAMS_QUERY = gql `
    query SearchParameters($tripStateId: ID!) {
        getTripState(tripStateId: $tripStateId) {
            tripStateId
            searchParameters {
                tripStateId
                startDate
                endDate
                hotelIds
                hotelTitle
                destinationIds
                destinationTitle
                legacyDestinationId
                legacyDestinationType
                rooms {
                    adults
                    childAges
                }
                tripType
                numberOfNights
                isNumberOfNightsAdjusted
                flightDetailsId
                departureAirports
                arrivalAirports
                adults
                childAges
                isArrivalAnywhere
                isDirectOnly
                cabin
                destinations {
                    id
                    type
                    title
                    isCached
                }
                flexibleDays
                duration
                wholeMonthStartDate
                wholeMonthEndDate
                dateSearchType
                flightJourneyType
                pickUpPoint {
                    id
                    name
                    type
                }
                dropOffPoint {
                    id
                    name
                    type
                }
            }
            transfersRequest {
                adults
                airportTitle
                airport
                childAges
                endDate
                resortId
                resortTitle
                startDate
            }
        }
    }
`;
export const TRANSFER_SEARCH_STATE_QUERY = gql `
    query CurrentTransferSearchParameters {
        currentTransferSearchParameters @client {
            adults
            airportTitle
            airport
            childAges
            endDate
            resortId
            resortTitle
            startDate
        }
    }
`;
