export var HotelExtraType;
(function (HotelExtraType) {
    HotelExtraType[HotelExtraType["Transfer"] = 2] = "Transfer";
})(HotelExtraType || (HotelExtraType = {}));
export var HotelPriceIncludesType;
(function (HotelPriceIncludesType) {
    HotelPriceIncludesType["BOARD_BASIS"] = "boardbasis";
    HotelPriceIncludesType["LOCAL_CHARGES"] = "localcharges";
    HotelPriceIncludesType["CANCELLATION"] = "cancellation";
    HotelPriceIncludesType["MANDATORY_TRANSFER"] = "mandatoryTransfer";
    HotelPriceIncludesType["HYPE_MESSAGES"] = "hypeMessages";
    HotelPriceIncludesType["STAY_COUNT"] = "stayCount,";
    HotelPriceIncludesType["NIGHTS_COUNT"] = "nightsCount";
    HotelPriceIncludesType["RETURN_FLIGHTS"] = "return_flights";
})(HotelPriceIncludesType || (HotelPriceIncludesType = {}));
export var QueryParamsEnum;
(function (QueryParamsEnum) {
    QueryParamsEnum["TRIP_STATE_ID"] = "tripId";
    QueryParamsEnum["CHECK_IN_DATE"] = "checkIn";
    QueryParamsEnum["CHECK_OUT_DATE"] = "checkOut";
    QueryParamsEnum["DESTINATION_ID"] = "destId";
    QueryParamsEnum["HOTEL_ID"] = "hotelId";
    QueryParamsEnum["DESTINATION_IDS"] = "destIds";
    QueryParamsEnum["DESTINATIONS"] = "destinations";
    QueryParamsEnum["HOTEL_IDS"] = "hotelIds";
    QueryParamsEnum["ROOMS"] = "rooms";
    QueryParamsEnum["ADULTS"] = "adults";
    QueryParamsEnum["CHILD_AGES"] = "childAges";
    QueryParamsEnum["LOWER"] = "lower";
    QueryParamsEnum["UPPER"] = "upper";
    QueryParamsEnum["HOTELFILTERS"] = "hotelFilters";
    QueryParamsEnum["FLIGHTFILTERS"] = "flightFilters";
    QueryParamsEnum["SORT"] = "sort";
    QueryParamsEnum["FSORT"] = "fsort";
    QueryParamsEnum["TRIP_TYPE"] = "tripType";
    QueryParamsEnum["FLIGHT_DETAILS_ID"] = "flightDetailsId";
    QueryParamsEnum["ACCOUNT_REDIRECT"] = "accountRedirect";
    QueryParamsEnum["ACCOUNT_DEEPLINK_REDIRECT"] = "accountDeeplinkRedirect";
    QueryParamsEnum["DEPARTURE_AIRPORTS"] = "depAirports";
    QueryParamsEnum["AIRPORT"] = "airport";
    QueryParamsEnum["AIRPORT_TITLE"] = "airportTitle";
    QueryParamsEnum["RESORT_ID"] = "resortId";
    QueryParamsEnum["RESORT_TITLE"] = "resortTitle";
    QueryParamsEnum["FEATURE_FLAGS"] = "featureFlags";
    QueryParamsEnum["DEEP_LINK_PRICE_TYPE"] = "deepLinkPriceType";
    QueryParamsEnum["ARRIVAL_AIRPORT"] = "arrivalAirport";
    QueryParamsEnum["FLIGHT_JOURNEY_TYPE"] = "flightJourneyType";
    QueryParamsEnum["FLIGHT_ID"] = "flightId";
    QueryParamsEnum["SOURCE"] = "source";
    QueryParamsEnum["EMAIL_ID"] = "emailid";
    QueryParamsEnum["LINK_ID"] = "linkid";
    QueryParamsEnum["QUEUE_ID"] = "queueId";
    QueryParamsEnum["QUOTE_ID"] = "quoteId";
    QueryParamsEnum["QUO"] = "QUO";
    QueryParamsEnum["CABIN"] = "cabin";
    QueryParamsEnum["TYPE"] = "destType";
    QueryParamsEnum["DURATION"] = "duration";
    QueryParamsEnum["FLEXIBLE_DAYS"] = "flexibleDays";
    QueryParamsEnum["DATE_SEARCH_TYPE"] = "dateSearchType";
    QueryParamsEnum["PICK_UP_POINT"] = "pickUpPoint";
    QueryParamsEnum["DROP_OFF_POINT"] = "dropOffPoint";
    QueryParamsEnum["USER_ID"] = "userid";
    QueryParamsEnum["BOARD_OPTION_ID"] = "boardOptionId";
    QueryParamsEnum["MOCK_ERROR_RETRY_ATTEMPTS"] = "mockErrorRetryAttempts";
    QueryParamsEnum["PRICE_DISPLAY_MODE"] = "priceDisplayMode";
})(QueryParamsEnum || (QueryParamsEnum = {}));
export var URLParamsEnum;
(function (URLParamsEnum) {
    URLParamsEnum["BOOKING_ID"] = "bookingId";
    URLParamsEnum["ID_STRING"] = "idString";
    URLParamsEnum["SLUG"] = "slug";
    URLParamsEnum["BOOKED_TRIP_ID"] = "bookedTripId";
    URLParamsEnum["CRS_ID"] = "csrId";
    URLParamsEnum["ESTAB_ID"] = "estabId";
    URLParamsEnum["AMOUNT"] = "amount";
    URLParamsEnum["PAYMENTMESSAGE"] = "paymentMessage";
})(URLParamsEnum || (URLParamsEnum = {}));
export var PaymentPlanEnum;
(function (PaymentPlanEnum) {
    PaymentPlanEnum[PaymentPlanEnum["Deposit"] = 1] = "Deposit";
    PaymentPlanEnum[PaymentPlanEnum["FullAmount"] = 2] = "FullAmount";
    PaymentPlanEnum[PaymentPlanEnum["Monthly"] = 3] = "Monthly";
})(PaymentPlanEnum || (PaymentPlanEnum = {}));
export var FeatureFlagEnum;
(function (FeatureFlagEnum) {
    FeatureFlagEnum["TEST_FEATURE"] = "TestFeature";
    FeatureFlagEnum["HOTEL_ONLY_TRANSFERS"] = "HotelOnlyTransfers";
    FeatureFlagEnum["FLIGHT_FUNNEL"] = "FlightFunnel";
    FeatureFlagEnum["TEXTBOX_PRICE_FILTER"] = "Textbox_price_filter";
    FeatureFlagEnum["PRICING_CALENDAR"] = "PricingCalendar";
    FeatureFlagEnum["GOOGLE_ADDRESS"] = "GoogleAddress";
    FeatureFlagEnum["ESTAB_LANDING_PRICING_CALENDAR"] = "EstabLandingPriceCalendar";
    FeatureFlagEnum["COMPLAINT_FORM"] = "ComplaintForm";
    FeatureFlagEnum["BETA_ESTAB_PAGE"] = "BetaEstabPage";
    FeatureFlagEnum["SHORTLIST"] = "Shortlist";
    FeatureFlagEnum["OLD_ESTAB_PAGE"] = "OldEstabPage";
})(FeatureFlagEnum || (FeatureFlagEnum = {}));
export var AnywhereSearchResultEnum;
(function (AnywhereSearchResultEnum) {
    AnywhereSearchResultEnum[AnywhereSearchResultEnum["ANYWHERE_ID"] = 1] = "ANYWHERE_ID";
    AnywhereSearchResultEnum["TITLE"] = "Anywhere";
    AnywhereSearchResultEnum[AnywhereSearchResultEnum["TYPE"] = 13] = "TYPE";
})(AnywhereSearchResultEnum || (AnywhereSearchResultEnum = {}));
export var PriceType;
(function (PriceType) {
    PriceType["Total"] = "TOTAL";
    PriceType["PerPerson"] = "PERPERSON";
    PriceType["PerNight"] = "PERNIGHT";
})(PriceType || (PriceType = {}));
export var TenantId;
(function (TenantId) {
    TenantId[TenantId["TR"] = 1] = "TR";
})(TenantId || (TenantId = {}));
export var Cabin;
(function (Cabin) {
    Cabin[Cabin["NotSpecified"] = 0] = "NotSpecified";
    Cabin[Cabin["Economy"] = 1] = "Economy";
    Cabin[Cabin["PremiumEconomy"] = 2] = "PremiumEconomy";
    Cabin[Cabin["First"] = 4] = "First";
    Cabin[Cabin["Business"] = 8] = "Business";
})(Cabin || (Cabin = {}));
export var FilterCategoryEnum;
(function (FilterCategoryEnum) {
    FilterCategoryEnum["BoardType"] = "boardTypes";
    FilterCategoryEnum["ChildDestination"] = "childDestinations";
    FilterCategoryEnum["GeneralSaleDeal"] = "generalSaleDealsOnly";
    FilterCategoryEnum["HolidayType"] = "holidayTypes";
    FilterCategoryEnum["HotelName"] = "hotelName";
    FilterCategoryEnum["PopertyType"] = "propertyTypes";
    FilterCategoryEnum["PriceRange"] = "priceRange";
    FilterCategoryEnum["PropertyAmenity"] = "propertyAmenities";
    FilterCategoryEnum["StarRating"] = "stars";
    FilterCategoryEnum["TripAdvisorRating"] = "tripAdvisorRatings";
})(FilterCategoryEnum || (FilterCategoryEnum = {}));
export var AttributeGroupEnum;
(function (AttributeGroupEnum) {
    AttributeGroupEnum[AttributeGroupEnum["Facilities"] = 1] = "Facilities";
    AttributeGroupEnum[AttributeGroupEnum["HolidayType"] = 2] = "HolidayType";
})(AttributeGroupEnum || (AttributeGroupEnum = {}));
export var FilterProductEnum;
(function (FilterProductEnum) {
    FilterProductEnum["HOTEL"] = "hotel";
    FilterProductEnum["FLIGHT"] = "flight";
})(FilterProductEnum || (FilterProductEnum = {}));
export var HotelCostBreakDownType;
(function (HotelCostBreakDownType) {
    HotelCostBreakDownType[HotelCostBreakDownType["Transfers"] = 23] = "Transfers";
})(HotelCostBreakDownType || (HotelCostBreakDownType = {}));
export var LocalStorageEnum;
(function (LocalStorageEnum) {
    LocalStorageEnum["PRICE_DISPLAY_MODE"] = "priceDisplayMode";
})(LocalStorageEnum || (LocalStorageEnum = {}));
