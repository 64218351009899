import * as React from 'react';
import { Anchor, Sticky, Styler, TypographyBody } from '@honeycomb/ui-core';
import { getBackgroundColor, getHeadingColor } from './utils';
export function PromoBar({ promoBarItem }) {
    const { backgroundColor, linkUrl, message, isSticky } = promoBarItem;
    const bgColor = getBackgroundColor(backgroundColor);
    const textColor = getHeadingColor(backgroundColor);
    return (React.createElement(Sticky, { disabled: !isSticky },
        React.createElement(Styler, { align: "center", bg: bgColor, py: 2 }, linkUrl ? (React.createElement(Anchor, { href: linkUrl, color: textColor, target: "_new" }, message)) : (React.createElement(TypographyBody, { color: textColor, size: "s" }, message)))));
}
