import { gql, makeVar } from '@apollo/client';
import { FlightSortCriterion, SortCriterion } from '../../contracts/types';
export const currentHotelSortCriterionVar = makeVar(SortCriterion.RecommendedHighestFirst);
export const currentFlightSortCriterionVar = makeVar(FlightSortCriterion.RecommendedHighestFirst);
export const currentSortCriterionCachePolicy = {
    currentHotelSortCriterion: {
        read() {
            return currentHotelSortCriterionVar();
        },
    },
    currentFlightSortCriterion: {
        read() {
            return currentFlightSortCriterionVar();
        },
    },
};
export const currentSortCriterionTypeDefs = gql `
    extend type Query {
        currentHotelSortCriterion: SortCriterion
        currentFlightSortCriterion: FlightSortCriterion
    }
`;
