function getPopUpPageType(pageUrl, categoryUrl) {
    if (!categoryUrl.trim())
        return null;
    try {
        const categoryUrls = JSON.parse(categoryUrl);
        const keys = Object.keys(categoryUrls);
        const matchedKey = keys.find((key) => {
            const urls = categoryUrls[key];
            return urls.includes(pageUrl);
        });
        return matchedKey !== null && matchedKey !== void 0 ? matchedKey : null;
    }
    catch (err) {
        console.log(`Failed to parse PopUp categoryUrls ${categoryUrl}`);
        return null;
    }
}
export function getPopUpContent(excludedUrl, categoryUrl, pageUrl, popUp) {
    var _a, _b;
    try {
        if (excludedUrl.trim()) {
            const exclusion = JSON.parse(excludedUrl);
            if (exclusion.includes(pageUrl))
                return null;
        }
    }
    catch (err) {
        console.log(`Failed to parse PopUp excludedUrls ${excludedUrl}`);
    }
    const pageType = getPopUpPageType(pageUrl, categoryUrl);
    const matchingPage = (_a = popUp === null || popUp === void 0 ? void 0 : popUp.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.pageType === pageType);
    if (matchingPage)
        return matchingPage;
    const allPage = (_b = popUp === null || popUp === void 0 ? void 0 : popUp.items) === null || _b === void 0 ? void 0 : _b.find((item) => item.pageType === 'All');
    return allPage !== null && allPage !== void 0 ? allPage : null;
}
