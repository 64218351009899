import { useStorageState } from 'react-storage-hooks';
export function storageObject() {
    const noop = () => null;
    const dummyStorage = {
        getItem: () => null,
        setItem: noop,
        removeItem: noop,
    };
    return typeof window !== 'undefined' ? window.localStorage : dummyStorage;
}
export function useLocalStorage(localStorageName) {
    return useStorageState(storageObject(), localStorageName);
}
