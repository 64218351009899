import * as React from 'react';
import { Styler } from '@honeycomb/ui-core';
import { DnataTravelLogo } from '../logos/DnataTravelLogo';
import { DnataTravelLogoStacked } from '../logos/DnataTravelLogoStacked';

export function MastheadLogo(): React.JSX.Element {
    return (
        <>
            <Styler display={{ m: 'none' }}>
                <DnataTravelLogoStacked h={32} va="middle" />
            </Styler>
            <Styler display={{ xs: 'none', m: 'block' }}>
                <DnataTravelLogo h={26} va="middle" />
            </Styler>
        </>
    );
}
