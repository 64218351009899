export const getSelectedOptionSummary = (selectedOptions, short = false) => {
    if (selectedOptions.length > 0) {
        const { title } = selectedOptions[0];
        const count = selectedOptions.length;
        const checkCount = count > 2 ? 's' : '';
        const setTitle = short ? `${title} + ${count - 1}` : `${title} (+ ${count - 1} other${checkCount})`;
        return count > 1 ? setTitle : title;
    }
    return '';
};
