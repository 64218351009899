import { PickUpDropOffType } from '../contracts/types';
export function convertSearchStateToTransferSearchRequest(searchParam) {
    var _a, _b, _c, _d, _e;
    let resortDetails = { type: PickUpDropOffType.Resort };
    let airportDetails = { type: PickUpDropOffType.Airport };
    const { pickUpPoint, dropOffPoint } = searchParam;
    if (pickUpPoint && dropOffPoint) {
        resortDetails = (pickUpPoint === null || pickUpPoint === void 0 ? void 0 : pickUpPoint.type) === PickUpDropOffType.Resort ? pickUpPoint : dropOffPoint;
        airportDetails = (pickUpPoint === null || pickUpPoint === void 0 ? void 0 : pickUpPoint.type) === PickUpDropOffType.Airport ? pickUpPoint : dropOffPoint;
    }
    return {
        adults: (_a = searchParam === null || searchParam === void 0 ? void 0 : searchParam.adults) !== null && _a !== void 0 ? _a : 2,
        childAges: (_b = searchParam === null || searchParam === void 0 ? void 0 : searchParam.childAges) !== null && _b !== void 0 ? _b : [],
        endDate: searchParam === null || searchParam === void 0 ? void 0 : searchParam.endDate,
        startDate: searchParam === null || searchParam === void 0 ? void 0 : searchParam.startDate,
        resortId: (resortDetails === null || resortDetails === void 0 ? void 0 : resortDetails.id) ? parseInt(resortDetails.id) : 0,
        resortTitle: (_c = resortDetails === null || resortDetails === void 0 ? void 0 : resortDetails.name) !== null && _c !== void 0 ? _c : '',
        airport: (_d = airportDetails === null || airportDetails === void 0 ? void 0 : airportDetails.id) !== null && _d !== void 0 ? _d : '',
        airportTitle: (_e = airportDetails === null || airportDetails === void 0 ? void 0 : airportDetails.name) !== null && _e !== void 0 ? _e : '',
    };
}
