import { gql, useQuery } from '@apollo/client';
import { NOTIFICATIONS } from './constants';
const SINGED_IN_CUSTOMER_QUERY = gql `
    query SignedInCustomer {
        signedInCustomer {
            title
            firstName
            lastName
            email
            phoneNumber
            address
            postalCode
            city
            county
            country
            trips {
                title
                tripId
                bundleRef
                canPayBalance
                hasNotification
                destination {
                    title
                }
                tripTravelledStatus
                startDate
                endDate
                receiptedStatus
                bookingTypes
                ${NOTIFICATIONS}
                estabId
                balance {
                    totalCost
                    totalCostFormatted
                    totalPaid
                    totalPaidFormatted
                    totalDue
                    totalDueFormatted
                }
            }
        }
    }
`;
export function useAccount() {
    const { data: signedInData, refetch: refreshSignedIn, loading: userLoading, error: userError, } = useQuery(SINGED_IN_CUSTOMER_QUERY, {
        notifyOnNetworkStatusChange: true,
        ssr: false,
        fetchPolicy: 'network-only',
    });
    const isLoggedIn = Boolean(signedInData === null || signedInData === void 0 ? void 0 : signedInData.signedInCustomer);
    return {
        isLoggedIn,
        userData: (signedInData === null || signedInData === void 0 ? void 0 : signedInData.signedInCustomer) || null,
        refreshSignedIn,
        userLoading,
        userError,
    };
}
