import { FlightJourneyType } from '../contracts/types';
export function convertSearchStateToFlightRequest(params) {
    const { startDate, endDate, departureAirports, arrivalAirports, isArrivalAnywhere, returnFrom, returnTo, adults, childAges, isDirectOnly, flightJourneyType, cabin, } = params;
    return {
        startDate,
        endDate,
        departureAirports: departureAirports || [],
        arrivalAirport: arrivalAirports || [],
        isArrivalAnywhere: isArrivalAnywhere || false,
        returnFrom,
        returnTo,
        adults: adults || 2,
        childAges,
        isDirectOnly: isDirectOnly || false,
        flightJourneyType: flightJourneyType || FlightJourneyType.Return,
        cabin,
    };
}
