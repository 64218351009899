import deepMerge from 'deepmerge';
import { isBrowser } from './isBrowser';
export function persistTrackingData(data, shouldMerge) {
    if (!isBrowser())
        return;
    const trackingCache = window.sessionStorage.getItem('trackingCache');
    if (trackingCache) {
        const newTrackingData = shouldMerge
            ? deepMerge(JSON.parse(trackingCache), data)
            : Object.assign(Object.assign({}, JSON.parse(trackingCache)), data);
        window.sessionStorage.setItem('trackingCache', JSON.stringify(newTrackingData));
        return;
    }
    window.sessionStorage.setItem('trackingCache', JSON.stringify(data));
}
