var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { QueryParamsEnum } from '@honeycomb/data';
import { QueryParamManager } from '../utils/QueryParamManager';
import { router } from '../utils/router';
export var AccountScreenEnum;
(function (AccountScreenEnum) {
    AccountScreenEnum["SIGN_IN"] = "/account/sign-in";
    AccountScreenEnum["SIGN_UP"] = "/account/sign-up";
    AccountScreenEnum["FORGOT_PASSWORD"] = "/account/forgotten-password";
    AccountScreenEnum["CHANGE_PASSWORD"] = "/account/token-change-password";
    AccountScreenEnum["VERIFY_ACCOUNT"] = "/account/verify";
})(AccountScreenEnum || (AccountScreenEnum = {}));
function encodeAccountRedirect(plain) {
    return plain
        .split('')
        .map((c) => c.charCodeAt(0).toString(16).padStart(2, '0'))
        .join('');
}
function decodeAccountRedirect(encoded) {
    return encoded
        .split(/(\w\w)/g)
        .filter((p) => !!p)
        .map((c) => String.fromCharCode(parseInt(c, 16)))
        .join('');
}
export function calcAccountRoute(screen) {
    var _a, _b, _c, _d;
    const accountRedirect = (_a = router === null || router === void 0 ? void 0 : router.currentParams()) === null || _a === void 0 ? void 0 : _a.get(QueryParamsEnum.ACCOUNT_REDIRECT);
    const accountDeeplinkRedirect = (_b = router === null || router === void 0 ? void 0 : router.currentParams()) === null || _b === void 0 ? void 0 : _b.get(QueryParamsEnum.ACCOUNT_DEEPLINK_REDIRECT);
    const currentAccountRoute = (_c = router.currentRoute()) === null || _c === void 0 ? void 0 : _c.startsWith('/account');
    const oldRoute = new QueryParamManager((_d = router.currentRoute()) !== null && _d !== void 0 ? _d : '/', router.currentParams()).toString();
    const redirectIfRouteStartsWithAccount = currentAccountRoute ? '/' : oldRoute;
    if (!screen && accountDeeplinkRedirect && currentAccountRoute) {
        return accountDeeplinkRedirect;
    }
    if (!screen) {
        return accountRedirect ? decodeAccountRedirect(accountRedirect) : redirectIfRouteStartsWithAccount;
    }
    let paramManager = new QueryParamManager(screen, router.currentParams());
    if (!accountRedirect) {
        paramManager = new QueryParamManager(screen, new URLSearchParams());
        paramManager.add(QueryParamsEnum.ACCOUNT_REDIRECT, encodeAccountRedirect(oldRoute));
    }
    return paramManager.toString();
}
export function accountScreenRouting(screen) {
    return __awaiter(this, void 0, void 0, function* () {
        yield router.push(undefined, () => [calcAccountRoute(screen)]);
    });
}
