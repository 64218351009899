import * as React from 'react';
import { useGlobalContext, FooterMenu as FooterMenuType, LinkGroup, LinkItem } from '@honeycomb/data';
import {
    Anchor,
    Container,
    Divider,
    FacebookIcon,
    FlexGrid,
    InstagramIcon,
    Stacker,
    Styler,
    SvgIconProps,
    TypographyBody,
    TypographyHeading,
    XIcon,
} from '@honeycomb/ui-core';
import { getCurrentDate, interjectResourceString } from '@honeycomb/ui';
import { DnataTravelLogoWhite } from './logos/DnataTravelLogoWhite';
import { getPaymentCardLogoMono } from './PaymentOptionsLogo';

function SocialLink(props: { icon: React.ReactElement<SvgIconProps>; href: string }) {
    const { icon, href } = props;
    return (
        <Anchor display="inline-flex" c="common.white" href={href}>
            {React.cloneElement(icon, { size: 's' })}
        </Anchor>
    );
}

export function Footer() {
    const {
        resourceStrings: { DT_FOOTER_CONFIDENCE_MESSAGE, COPYRIGHT, WEACCEPT },
        globalContents: { FOOTER },
    } = useGlobalContext();

    const { content } = FOOTER || {};
    const { groups, cardItems } = (content as FooterMenuType) || {};

    return (
        <Styler component="footer" bg="grey.tint.700" c="common.white">
            <Container py={{ xs: 10, m: 16 }}>
                <FlexGrid container={true}>
                    <FlexGrid xs={12} l={5}>
                        <Stacker spacing={4} mr={{ l: 30 }} mb={{ xs: 8, l: 0 }}>
                            <div>
                                <DnataTravelLogoWhite h={32} />
                            </div>

                            {/* Social media links */}
                            <Stacker direction="row" spacing={4} alignItems="center">
                                <SocialLink icon={<FacebookIcon />} href="https://www.facebook.com/dnatatravel" />
                                <SocialLink icon={<XIcon />} href="https://twitter.com/dnatatravel" />
                                <SocialLink icon={<InstagramIcon />} href="https://www.instagram.com/dnatatravel" />
                            </Stacker>

                            <TypographyBody size="xs" color="grey.tint.200">
                                {DT_FOOTER_CONFIDENCE_MESSAGE}
                            </TypographyBody>
                        </Stacker>
                    </FlexGrid>
                    <FlexGrid xs={12} l={7}>
                        <FlexGrid container={true} columnSpacing={6} rowSpacing={8}>
                            {groups?.map((group: LinkGroup, groupIndex: number) => {
                                return (
                                    <FlexGrid xs={12} m="equal" key={`link-menu-group-${groupIndex.toString()}`}>
                                        <TypographyBody component="h4" size="s" mb={3} weight="semiBold">
                                            {group.title}
                                        </TypographyBody>
                                        <Stacker spacing={3} alignItems="flex-start">
                                            {group.links.map((item: LinkItem, itemIndex: number) => {
                                                if (!item.url || !item.text) {
                                                    return null;
                                                }
                                                return (
                                                    <Anchor
                                                        size="s"
                                                        underline="none"
                                                        href={item.url}
                                                        key={`link-menu-item-${groupIndex.toString()}-${itemIndex.toString()}`}
                                                    >
                                                        {item.text}
                                                    </Anchor>
                                                );
                                            })}
                                        </Stacker>
                                    </FlexGrid>
                                );
                            })}
                        </FlexGrid>
                    </FlexGrid>
                </FlexGrid>

                <Divider mt={{ l: 14, xs: 12 }} mb={4} color="grey.tint.500" />

                <FlexGrid
                    container={true}
                    direction="row"
                    justifyContent="space-between"
                    spacing={5}
                    alignItems="flex-start"
                    flexWrap="wrap"
                >
                    <FlexGrid s={12} l="auto">
                        {/* Copyright message */}
                        <TypographyBody size="xs" color="grey.tint.200">
                            {interjectResourceString(COPYRIGHT, String(getCurrentDate().year))}
                        </TypographyBody>
                    </FlexGrid>

                    {/* Payment methods */}
                    <FlexGrid s={12} l="auto">
                        <TypographyHeading component="h4" size="2xs" color="common.white" weight="bold" mb={2}>
                            {WEACCEPT}
                        </TypographyHeading>
                        <div>
                            <FlexGrid container={true} spacing={2} justifyContent="flex-start" flexWrap="wrap">
                                {cardItems?.map((item) => {
                                    const name = item.icon?.toLowerCase();
                                    if (!name) {
                                        return null;
                                    }
                                    return <FlexGrid key={name}>{getPaymentCardLogoMono(name)}</FlexGrid>;
                                })}
                            </FlexGrid>
                        </div>
                    </FlexGrid>
                </FlexGrid>
            </Container>
        </Styler>
    );
}
