import { DateSearchType, } from '../contracts/types';
import { AnywhereSearchResultEnum } from './enums';
function newRooms(rooms) {
    return rooms.map((room) => {
        return {
            adults: room.adults,
            childAges: room.childAges,
        };
    });
}
export function convertSearchStateToHotelRequest(params) {
    const { startDate, endDate, destinationIds, hotelIds, rooms, destType, userId } = params;
    return Object.assign(Object.assign({ startDate,
        endDate }, (destType && (destinationIds === null || destinationIds === void 0 ? void 0 : destinationIds[0])
        ? { destination: { id: destinationIds === null || destinationIds === void 0 ? void 0 : destinationIds[0], type: destType } }
        : { destinationIds })), { hotelIds, rooms: newRooms(rooms), userId });
}
export function mapDestinations(destinations) {
    return destinations === null || destinations === void 0 ? void 0 : destinations.map((d) => (d === null || d === void 0 ? void 0 : d.id) === AnywhereSearchResultEnum.ANYWHERE_ID && (d === null || d === void 0 ? void 0 : d.type) === AnywhereSearchResultEnum.TYPE
        ? {
            id: AnywhereSearchResultEnum.ANYWHERE_ID,
            title: AnywhereSearchResultEnum.TITLE,
            type: AnywhereSearchResultEnum.TYPE,
            isCached: true,
            subTitle: d === null || d === void 0 ? void 0 : d.subTitle,
        }
        : {
            id: d === null || d === void 0 ? void 0 : d.id,
            title: d === null || d === void 0 ? void 0 : d.title,
            isCached: d === null || d === void 0 ? void 0 : d.isCached,
            type: d === null || d === void 0 ? void 0 : d.type,
            subTitle: d === null || d === void 0 ? void 0 : d.subTitle,
        });
}
export function convertSearchStateToFlexibleHotelRequest(params) {
    const { startDate, endDate, rooms, destinations, flexibleDays, duration, wholeMonthEndDate, wholeMonthStartDate, dateSearchType, userId, } = params;
    return {
        startDate: dateSearchType === DateSearchType.ChooseDate ? startDate : wholeMonthStartDate || startDate,
        endDate: dateSearchType === DateSearchType.ChooseDate ? endDate : wholeMonthEndDate || endDate,
        destinations: destinations && (destinations === null || destinations === void 0 ? void 0 : destinations.length) > 0 ? mapDestinations(destinations) : [],
        rooms: newRooms(rooms),
        flexibleDays: dateSearchType === DateSearchType.ChooseDate ? flexibleDays : undefined,
        duration: dateSearchType === DateSearchType.ChooseMonth ? duration : undefined,
        userId,
    };
}
export function convertSearchStateToFlexibleHolidayRequest(params) {
    const { startDate, endDate, rooms, destinations, departureAirports, cabin, flexibleDays, duration, wholeMonthEndDate, wholeMonthStartDate, dateSearchType, userId, } = params;
    return {
        startDate: dateSearchType === DateSearchType.ChooseDate ? startDate : wholeMonthStartDate || startDate,
        endDate: dateSearchType === DateSearchType.ChooseDate ? endDate : wholeMonthEndDate || endDate,
        destinations: destinations && (destinations === null || destinations === void 0 ? void 0 : destinations.length) > 0 ? mapDestinations(destinations) : [],
        rooms: newRooms(rooms),
        departureAirports: departureAirports || [],
        cabin,
        flexibleDays: dateSearchType === DateSearchType.ChooseDate ? flexibleDays : undefined,
        duration: dateSearchType === DateSearchType.ChooseMonth ? duration : undefined,
        userId,
    };
}
export function convertSearchStateToHolidayRequest(params) {
    const { startDate, endDate, destinationIds, rooms, hotelIds, departureAirports, cabin, destType, userId } = params;
    return Object.assign(Object.assign({ startDate,
        endDate }, (destType && (destinationIds === null || destinationIds === void 0 ? void 0 : destinationIds[0])
        ? { destination: { id: destinationIds === null || destinationIds === void 0 ? void 0 : destinationIds[0], type: destType } }
        : { destinationIds: destinationIds || undefined })), { hotelIds, rooms: newRooms(rooms), departureAirports: departureAirports || [], cabin,
        userId });
}
