import * as React from 'react';
import Head from 'next/head';
export function PageHead(props) {
    const { pageTitle, pageDescription, canonical, linkTags } = props;
    return (React.createElement(Head, null,
        React.createElement("title", null, pageTitle),
        pageTitle && React.createElement("meta", { name: "og:title", content: pageTitle }),
        pageTitle && React.createElement("meta", { name: "title", content: pageTitle }),
        pageDescription && React.createElement("meta", { name: "og:description", content: pageDescription }),
        pageDescription && React.createElement("meta", { name: "description", content: pageDescription }),
        canonical && React.createElement("link", { rel: "canonical", href: canonical }), linkTags === null || linkTags === void 0 ? void 0 :
        linkTags.map((link, index) => {
            const { url, language } = link;
            return React.createElement("link", { key: index.toString(), rel: "alternate", href: url, hrefLang: language });
        }),
        React.createElement("meta", { name: "og:image", content: "/images/favicon-228x228.png" })));
}
