import { DateTime } from 'luxon';
export const getDuration = (durationInMinutes) => {
    const hours = Math.floor(durationInMinutes / 60);
    const housStr = String(hours).padStart(2, '0');
    const minutes = durationInMinutes - hours * 60;
    const minsStr = String(minutes).padStart(2, '0');
    return `${housStr}h ${minsStr}m`;
};
export const getCurrentDate = () => DateTime.utc();
export const getDateString = (date) => {
    return date !== undefined && date.isValid ? date.toFormat('LLL dd, yyyy', DateTime.DATE_MED) : 'Invalid date';
};
export const getHoursFormat = (date) => {
    return date !== undefined && date.isValid ? date.toFormat('HH', DateTime.DATE_MED) : 'Invalid date';
};
export const getMinutesFormat = (date) => {
    return date !== undefined && date.isValid ? date.toFormat('mm', DateTime.DATE_MED) : 'Invalid date';
};
export const getHoursFromISO = (date) => {
    const parsedDate = DateTime.fromISO(date || getDateString(getCurrentDate()), { zone: 'UTC' });
    return parsedDate.isValid ? getHoursFormat(parsedDate) : undefined;
};
export const getMinutesFromISO = (date) => {
    const parsedDate = DateTime.fromISO(date || getDateString(getCurrentDate()), { zone: 'UTC' });
    return parsedDate.isValid ? getMinutesFormat(parsedDate) : undefined;
};
const months = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
};
export const stringToIsoFormat = (dateStr) => {
    const [, date, month, year] = dateStr.split(' ');
    const monthNo = months[month];
    return `${year}-${monthNo}-${date}T00:00:00.000Z`;
};
export const getDayDiff = (from, to, zone) => {
    const fromDateTime = zone ? DateTime.fromISO(from, { zone: 'UTC' }) : DateTime.fromISO(from);
    const toDateTime = zone ? DateTime.fromISO(to, { zone: 'UTC' }) : DateTime.fromISO(to);
    if (!fromDateTime.isValid || !toDateTime.isValid) {
        return 0;
    }
    return toDateTime.startOf('day').diff(fromDateTime.startOf('day'), 'days').days;
};
export function addDays(date, adjustDays, zone) {
    const toDateTime = zone ? DateTime.fromISO(date, { zone: 'UTC' }) : DateTime.fromISO(date);
    return toDateTime.startOf('day').plus({ days: adjustDays }).toISO();
}
export var DateFormats;
(function (DateFormats) {
    DateFormats["Default"] = "DEFAULT";
    DateFormats["Fulldate"] = "FULLDATE";
    DateFormats["Iso"] = "ISO";
    DateFormats["Raw"] = "RAW";
    DateFormats["Time"] = "TIME";
    DateFormats["Shortdate"] = "SHORTDATE";
    DateFormats["Shortdaydate"] = "SHORTDAYDATE";
    DateFormats["None"] = "NONE";
    DateFormats["DefaultFromISO"] = "DEFAULTFROMISO";
    DateFormats["DefaultFromISOYY"] = "DEFAULTFROMISOYY";
    DateFormats["Shortdaydateyear"] = "SHORTDAYDATEYEAR";
    DateFormats["Shortmonthyear"] = "SHORTMONTHYEAR";
    DateFormats["ShortDateOrdinal"] = "SHORTDATEORDINAL";
})(DateFormats || (DateFormats = {}));
export function getFormattedDate(format, date) {
    if (!date) {
        return '';
    }
    const dateISO = DateTime.fromISO(date, { zone: 'UTC' });
    switch (format) {
        case DateFormats.Raw:
            return new Date(date);
        case DateFormats.Iso:
            return dateISO.toString();
        case DateFormats.Default:
            return dateISO.toFormat('EEE dd MMM yyyy');
        case DateFormats.DefaultFromISO:
            return DateTime.fromISO(date).toFormat('EEE dd MMM yyyy');
        case DateFormats.DefaultFromISOYY:
            return DateTime.fromISO(date).toFormat('EEE d MMM yy');
        case DateFormats.Time:
            return DateTime.fromISO(date).toFormat('HH:mm');
        case DateFormats.Shortdate:
            return dateISO.toFormat(`dd'/'LL'/'yyyy`);
        case DateFormats.Shortdaydate:
            return DateTime.fromISO(date).toFormat('EEE, dd MMM');
        case DateFormats.Shortdaydateyear:
            return DateTime.fromISO(date).toFormat('dd MMM yyyy');
        case DateFormats.Shortmonthyear:
            return DateTime.fromISO(date).toFormat('MMM yyyy');
        default:
            return new Date(date).toISOString();
    }
}
export function getIsoDateString(date, format) {
    switch (format) {
        case DateFormats.ShortDateOrdinal:
            return DateTime.fromFormat(date.replace(/(\d)(st|nd|rd|th)/g, (_match, number) => number), 'd MMMM yyyy', { zone: 'UTC' }).toISO();
        default:
            return date;
    }
}
export const toUTCDate = (date) => {
    return date
        ? new Date(`${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`).toISOString()
        : undefined;
};
export const addDay = (date, duration) => {
    const newDate = new Date(date); // Create a copy of the original date
    newDate.setDate(newDate.getDate() + duration); // Modify the date
    return newDate; // Return the updated date
};
