export var SubscribeEmailEnum;
(function (SubscribeEmailEnum) {
    SubscribeEmailEnum["SETEMAIL"] = "SetEmail";
    SubscribeEmailEnum["SETERRORMESSAGE"] = "SetErrorMessage";
    SubscribeEmailEnum["ISSUBSCRIBE"] = "ISSUBSCRIBE";
    SubscribeEmailEnum["SETSUBSCRIBE"] = "SetSubscribe";
})(SubscribeEmailEnum || (SubscribeEmailEnum = {}));
export const reducer = (state, action) => {
    switch (action.type) {
        case SubscribeEmailEnum.SETEMAIL:
            return Object.assign(Object.assign({}, state), { email: action.email, reasonCode: action.source });
        case SubscribeEmailEnum.SETERRORMESSAGE:
            return Object.assign(Object.assign({}, state), { error: action.error });
        case SubscribeEmailEnum.ISSUBSCRIBE:
            return Object.assign(Object.assign({}, state), { loading: true, error: '' });
        case SubscribeEmailEnum.SETSUBSCRIBE:
            return Object.assign(Object.assign({}, state), { success: true, loading: false, error: '', email: '' });
        default:
            return state;
    }
};
