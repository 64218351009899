function getPromoPageType(pageUrl, categoryUrl) {
    if (!categoryUrl.trim())
        return null;
    try {
        const categoryUrls = JSON.parse(categoryUrl);
        const keys = Object.keys(categoryUrls);
        const matchedKey = keys.find((key) => {
            const urls = categoryUrls[key];
            return urls.includes(pageUrl);
        });
        return matchedKey || null;
    }
    catch (err) {
        console.log(`Failed to parse promobar categoryUrls ${categoryUrl}`);
        return null;
    }
}
export function getPromoBarContent(excludedUrl, categoryUrl, pageUrl, query, promoBar) {
    var _a, _b, _c, _d, _e, _f;
    // Filter items based on UTM, filter items, where all the parameters (if any) are present in the url
    // of the page as query params and matches on value.
    let promoItems = [];
    try {
        if (excludedUrl.trim()) {
            const exclusion = JSON.parse(excludedUrl);
            if (exclusion.includes(pageUrl))
                return null;
        }
    }
    catch (err) {
        console.log(`Failed to parse promobar excludedUrls ${excludedUrl}`);
    }
    // Find an item where there are parameters and they match the query string.
    promoItems =
        (_b = (_a = promoBar === null || promoBar === void 0 ? void 0 : promoBar.items) === null || _a === void 0 ? void 0 : _a.filter((promoBarItem) => {
            return ((promoBarItem === null || promoBarItem === void 0 ? void 0 : promoBarItem.parameters) &&
                promoBarItem.parameters.length > 0 &&
                promoBarItem.parameters.every((x) => query[x.parameterName] === x.parameterValue));
        })) !== null && _b !== void 0 ? _b : [];
    // If no items are filtered then move to default one, where we dont have any parameters.
    if (promoItems.length === 0) {
        promoItems =
            (_d = (_c = promoBar === null || promoBar === void 0 ? void 0 : promoBar.items) === null || _c === void 0 ? void 0 : _c.filter((promoBarItem) => {
                var _a;
                return !promoBarItem.parameters || ((_a = promoBarItem.parameters) === null || _a === void 0 ? void 0 : _a.length) === 0;
            })) !== null && _d !== void 0 ? _d : [];
    }
    const filteredPromoBar = Object.assign(Object.assign({}, promoBar), { items: promoItems });
    const pagetype = getPromoPageType(pageUrl, categoryUrl);
    const matchingPage = (_e = filteredPromoBar === null || filteredPromoBar === void 0 ? void 0 : filteredPromoBar.items) === null || _e === void 0 ? void 0 : _e.find((item) => item.pageType === pagetype);
    if (matchingPage)
        return matchingPage;
    const allPage = (_f = filteredPromoBar === null || filteredPromoBar === void 0 ? void 0 : filteredPromoBar.items) === null || _f === void 0 ? void 0 : _f.find((item) => item.pageType === 'All');
    return allPage !== null && allPage !== void 0 ? allPage : null;
}
