var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql, useMutation } from '@apollo/client';
import { callSideEffects } from '../../util';
const EMAIL_SUBSCRIBE_MUTATION = gql `
    mutation CreateEmailSubscribe($request: EmailSubscribeRequest!) {
        emailSignup(request: $request)
    }
`;
export function useEmailSubscribe(sideEffects) {
    const [emailSubscribeMutation, { error, loading }] = useMutation(EMAIL_SUBSCRIBE_MUTATION);
    const setEmailSubscribe = {
        emailSubscribe: (args) => __awaiter(this, void 0, void 0, function* () {
            const { data: mutationData } = yield emailSubscribeMutation({
                variables: args,
            });
            if (mutationData) {
                callSideEffects(sideEffects, mutationData);
            }
        }),
        error,
        loading,
    };
    return [setEmailSubscribe];
}
