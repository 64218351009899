import { makeVar } from '@apollo/client';
import { PriceType } from '../../util';
export const currentDeepLinkPriceTypeVar = makeVar(PriceType.Total);
export const currentDeepLinkPriceTypeCachePolicy = {
    currentDeepLinkPriceType: {
        read() {
            return currentDeepLinkPriceTypeVar();
        },
    },
};
