export const HOTEL_ITINERARY = `
    ... on HotelItinerary {
        type
        stays {
            hotel {
                estabId
                name
                starRating
                geography {
                    location
                    province
                    country
                    address
                }
                overview
                attributeGroups {
                    attributes {
                        code
                        name
                    }
                    name
                    type
                }
            }
            startDate
            endDate
            reviewStatus
            reviewedByCustomer
            rooms {
                title
                boardType
                adults
                childAges
                guests {
                    ageOnReturn
                }
            }
        }
    }
`;
export const FLIGHT_ITINERARY = `
    ... on FlightItinerary {
        type
        flights {
            departureDate
            arrivalDate
            duration
            segments {
                departureDate
                departureAirport {
                    code
                    title
                    legacyDestinationType
                    legacyDestinationId
                    destinationTitle
                }
                departureTerminal
                arrivalDate
                arrivalAirport {
                    code
                    title
                    legacyDestinationType
                    legacyDestinationId
                    destinationTitle
                }
                arrivalTerminal
                flightNumber
                cabin
                aircraft
                marketingCarrier
                operatingCarrier
            }
            adults
            children
            infants
        }
    }
`;
export const INSURANCE_ITINERARY = `
    ... on InsuranceItinerary {
        type
        insurance {
            itineraryId
            description
            destinationId
            destinationName
            adults
            children
            infants
            specialRequests
            startDate
            endDate
        }
    }
`;
export const CARHIRE_ITINERARY = `
    ... on CarHireItinerary {
        type
        carHire {
            itineraryId
            vendorCode
            vendorName
            pickUpDate
            pickUpPointCode
            pickUpPointName
            dropOffDate
            dropOffPointCode
            dropOffPointName
            carDescription
            sIPPCode
            doorCount
            model
            connectingFlightNumber
        }
    }
`;
export const TRANSFER_ITINERARY = `
    ... on TransferItinerary {
        type
        transfers {
            itineraryId
            transferDate
            pickUpPoint {
                endPointType
                airportCode
                airportName
                resortId
                resortName
            }
            dropOffPoint {
                endPointType
                airportCode
                airportName
                resortId
                resortName
            }
            transferTitle
            address
            adults
            children
            infants
            outboundFlight {
                flightNumber
                arrivalDate
            }
            inboundFlight {
                flightNumber
                arrivalDate
            }
        }
    }
`;
export const AIRPORT_PARKING_ITINERARY = `
    ... on AirportParkingItinerary {
        parking {
            dropOffDate
            pickUpDate
            parkingSiteCode
            parkingSiteName
            notes
            outboundFlight {
                flightNumber
                departureDate
            }
            inboundFlight {
                flightNumber
                arrivalDate
            }
            airport {
                code
                title
                legacyDestinationType
                legacyDestinationId
                destinationTitle
            }
        }
    }
`;
export const ATTRACTIONS_ITINERARY = `
    ... on AttractionsItinerary {
        type
        tickets {
            itineraryId
            description
            startDate
            endDate
            adults
            children
            infants
            attractionDestination {
                provinceId
                regionId
                regionTitle
            }
        }
        transfer
    }
`;
export const NOTIFICATIONS = `
    notifications {
        ... on BalanceNotification {
            type
            bookingId
            tripId
            dueDate
            lastName
            currencySymbol
            currencyCode
            hasFuturePayments
            balanceDueAmount
            balanceDueAmountFormatted
        }
        ... on LeaveReviewNotification {
            type
            bookingId
            startDate
            lastName
        }
        ... on ScheduledChangeNotification {
            type
            bookingId
            dueDate
            lastName
            csrId
            startDate
            agentName
        }
        ... on CsrQuestionNotification {
            type
            bookingId
            dueDate
            lastName
            csrId
            startDate
            agentName
        }
        ... on PayAtHotelNotification {
            type
            bookingId
            startDate
            dueDate
            lastName
            currencySymbol
            currencyCode
            balanceDueAmount
            balanceDueAmountFormatted
        }
        ... on FlightCheckInNotification {
            type
            bookingId
            startDate
            dueDate
            checkInType
            lastName
            currencyCode
        }
        ... on PassengerApiNotification {
            type
            bookingId
            dueDate
            lastName
            startDate
        }
        ... on OtherNotification {
            type
            bookingId
        }
    }
`;
