import React from 'react';
import { Container, Button, Stacker, TypographyBody, TypographyHeading, TypographyHero, FlexGrid, useHoneycombTheme, HoneycombThemeProvider, } from '@honeycomb/ui-core';
import { useGlobalContext } from '@honeycomb/data';
import { getErrorPageContent } from './getErrorPageContent';
export function UnexpectedError({ correlationId, children, error, resetErrorBoundary, contentOverrides, }) {
    const routeBack = () => {
        if (typeof window === 'undefined')
            return;
        window.history.back();
    };
    const routeHome = () => {
        if (resetErrorBoundary) {
            resetErrorBoundary();
        }
        if (typeof window === 'undefined')
            return;
        window.location.href = '/';
    };
    const { title, subtitle, backButton, homeButton, supportTitle, supportText, supportTel } = getErrorPageContent(error === null || error === void 0 ? void 0 : error.statusCode, contentOverrides);
    const { configSettings: { HCResponseTapClassName }, } = useGlobalContext();
    React.useEffect(() => {
        console.log('Error:', error);
        if (!error || (error === null || error === void 0 ? void 0 : error.statusCode) === 404)
            return;
        fetch('/log/error', {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'x-trp-correlation-id': correlationId || '',
            },
            body: JSON.stringify({ message: error.message, stack: error.stack, source: 'Crane' }),
        });
    });
    const { theme: { palette }, } = useHoneycombTheme();
    return (React.createElement(HoneycombThemeProvider, { theme: { palette: { background: { body: palette.background.offset } } } },
        React.createElement(Container, { py: { xs: 3, m: 10 } },
            React.createElement(FlexGrid, { container: true, flexDirection: { xs: 'column', m: 'row-reverse' }, flexWrap: "wrap", columnSpacing: 10 },
                children && (React.createElement(FlexGrid, { m: "equal", miw: { xs: '100%', m: 0 } }, children)),
                React.createElement(FlexGrid, { m: "equal" },
                    React.createElement(TypographyHero, { size: "2xl", color: "accent.base", component: "h1", mt: { xs: 3, m: 13 }, mb: { xs: 2, m: 6 }, transform: "uppercase" }, title),
                    React.createElement(TypographyBody, null, subtitle),
                    React.createElement(Stacker, { direction: "row", spacing: 5, my: { xs: 5, m: 6 } },
                        React.createElement(Button, { onClick: routeBack, color: "primary", size: "l" }, backButton),
                        React.createElement(Button, { onClick: routeHome, href: "/", variant: "outlined", size: "l" }, homeButton)),
                    React.createElement(TypographyHeading, { size: "xs", mb: 3 }, supportTitle),
                    React.createElement(TypographyBody, { size: "s", color: "text.light" }, supportText),
                    React.createElement(TypographyBody, { fw: "bold", color: "accent.base", mb: 6 },
                        React.createElement("span", { className: HCResponseTapClassName !== null && HCResponseTapClassName !== void 0 ? HCResponseTapClassName : 'rTapNumber000000' }, supportTel)),
                    correlationId && (React.createElement(TypographyBody, { mt: 6, size: "xs", color: "text.light" },
                        "Error code: ",
                        correlationId)),
                    error && ['development', 'test'].includes(process.env.NODE_ENV) ? (React.createElement(TypographyBody, { size: "xs", color: "text.light" }, error.message)) : null)))));
}
