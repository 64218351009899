import {
    AmexLogoMono,
    ApplePayLogoMono,
    DinersClubLogoMono,
    GPayLogoMono,
    MastercardLogoMono,
    PayPalLogoMono,
    StylerProps,
    VisaLogoMono,
} from '@honeycomb/ui-core';
import React from 'react';

const logoMap: { [key: string]: React.ReactElement<StylerProps> } = {
    visa: <VisaLogoMono />,
    mastercard: <MastercardLogoMono />,
    diners: <DinersClubLogoMono />,
    amex: <AmexLogoMono />,
    gpay: <GPayLogoMono />,
    paypal: <PayPalLogoMono />,
    applepay: <ApplePayLogoMono />,
};

export function getPaymentCardLogoMono(name: string): React.ReactElement<StylerProps> | null {
    const logo = logoMap[name];
    if (!logo) {
        return null;
    }
    return React.cloneElement(logo, { fill: 'common.white', h: 32, va: 'middle' });
}
