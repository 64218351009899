import { URLParamsEnum } from '@honeycomb/data';
export class QueryParamManager {
    constructor(route, params) {
        this.route = route;
        this.params = params;
        Object.values(URLParamsEnum).forEach((val) => this.params.delete(val));
    }
    add(key, value) {
        this.params.set(key, value.toString());
        return this;
    }
    addMultiple(values) {
        Object.entries(values).forEach(([key, value]) => {
            if (value) {
                this.add(key, value);
            }
        });
        return this;
    }
    remove(key) {
        if (this.params.get(key)) {
            this.params.delete(key);
        }
        else {
            // Remove any elements that are derived/nested from the key
            const keys = this.params.keys();
            let result = keys.next();
            const matchingKeys = [];
            while (!result.done) {
                if (result.value.startsWith(`${key}.`)) {
                    matchingKeys.push(result.value);
                }
                result = keys.next();
            }
            matchingKeys.forEach((value) => this.params.delete(value));
        }
        return this;
    }
    removeMultiple(keys) {
        keys.forEach((key) => {
            this.remove(key);
        });
        return this;
    }
    toString() {
        return `${this.route}?${this.params.toString()}`;
    }
}
