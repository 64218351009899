import { makeVar, gql, useReactiveVar } from '@apollo/client';
export const isPageRoutingVar = makeVar(false);
export const isPageRoutingCachePolicy = {
    isPageRouting: {
        read() {
            return isPageRoutingVar();
        },
    },
};
export const isPageRoutingTypeDefs = gql `
    extend type Query {
        isPageRouting: Boolean!
    }
`;
export function usePageRouting() {
    function setIsPageRoutingState(value) {
        isPageRoutingVar(value);
    }
    const isPageRouting = useReactiveVar(isPageRoutingVar);
    return { isPageRouting, setIsPageRoutingState };
}
