import { UnexpectedErrorContent, UnexpectedErrorContentSchema } from '@honeycomb/ui';

const defaultContent: Required<UnexpectedErrorContentSchema> = {
    title: 'An error has occurred.',
    subtitle: `Please try one of the below options to get back on track.`,
    backButton: 'Go back',
    homeButton: 'Go to homepage',
    supportTitle: 'Still not working?',
    supportText: 'Contact our support team on:',
    supportTel: '80036282',
};

export const errorPageContent: Required<UnexpectedErrorContent> = {
    404: {
        ...defaultContent,
        title: 'Page not found',
        subtitle: `Sorry, that page does not exist.`,
    },
    500: {
        ...defaultContent,
    },
};
