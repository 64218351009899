var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql, useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { AirportListType, } from '../../contracts/types';
export const AIRPORT_AUTOCOMPLETER_RESULTS_QUERY = gql `
    query AirportAutocompleterResults($request: AirportAutocompleterRequest!) {
        airportAutocompleterResults(request: $request) {
            airports {
                ... on AirportGroup {
                    __typename
                    codes
                    title
                    country
                    subAirports {
                        code
                        title
                        country
                    }
                }
                ... on SingleAirport {
                    __typename
                    code
                    title
                    country
                }
            }
        }
    }
`;
export function useAirportAutocompleterResults() {
    const [getAirportsQuery, airportResults] = useLazyQuery(AIRPORT_AUTOCOMPLETER_RESULTS_QUERY, {
        notifyOnNetworkStatusChange: true,
        ssr: false,
        variables: {
            request: {
                searchTerm: '',
                airportList: AirportListType.Departure,
            },
        },
    });
    const getAirports = useCallback((request) => __awaiter(this, void 0, void 0, function* () {
        const { data } = yield getAirportsQuery({ variables: { request } });
        return data;
    }), [getAirportsQuery]);
    return [getAirports, airportResults];
}
