var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
let datadogRum;
const dataDogConfigSettingsKeys = [
    'HcDataDogRealUserMonitoringEnabled',
    'HcDataDogApplicationId',
    'HcDataDogClientToken',
    'HcDataDogSessionReplaySampleRate',
    'HcDataDogSessionSampleRate',
    'HcDataDogTrackLongTasks',
    'HcDataDogTrackResources',
    'HcDataDogTrackUserInteractions',
];
export const getDataDogConfigSettings = (configSettings) => configSettings === null || configSettings === void 0 ? void 0 : configSettings.reduce((acc, setting) => {
    if (dataDogConfigSettingsKeys.includes(setting.key)) {
        acc[setting.key] = setting.value;
    }
    return acc;
}, {});
export function loadAndInitDataDogRum(config) {
    return __awaiter(this, void 0, void 0, function* () {
        datadogRum = datadogRum !== null && datadogRum !== void 0 ? datadogRum : (yield import('@datadog/browser-rum')).datadogRum;
        datadogRum.init(Object.assign({ site: 'datadoghq.com', allowedTracingUrls: [
                /https:\/\/.*\.dnatatravel-services\.com/,
                /https:\/\/.*\.travelrepublic\.co\.uk/,
                /https:\/\/.*\.travelrepublic\.ie/,
            ], defaultPrivacyLevel: 'mask-user-input', beforeSend: (event) => {
                var _a;
                if (event.type === 'error' && ((_a = event.error) === null || _a === void 0 ? void 0 : _a.source) === 'report') {
                    return false;
                }
                return true;
            } }, config));
    });
}
export function addDatadogRumAction(name, tags) {
    if (!datadogRum)
        return;
    datadogRum.addAction(name, tags);
}
