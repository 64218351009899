import { gql, useQuery } from '@apollo/client';
import { NotificationTypes } from '../../contracts/types';
import { currentNotificationVar } from './currentNotification';
export function useNotification() {
    const NOTIFICATION_QUERY = gql `
        query Notification {
            currentNotification @client {
                isOpen
                notificationType
                message
                heading
                subHeading
                autoHideDuration
            }
        }
    `;
    const currentNotificationState = useQuery(NOTIFICATION_QUERY, { ssr: false });
    function setCurrentNotificationState({ isOpen, notificationType, message, heading, subHeading, autoHideDuration, }) {
        currentNotificationVar({
            isOpen,
            notificationType: notificationType || NotificationTypes.Notification,
            message: message || '',
            heading: heading || '',
            subHeading: subHeading || '',
            autoHideDuration: autoHideDuration || 3000,
        });
    }
    return [currentNotificationState, setCurrentNotificationState];
}
