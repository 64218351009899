import { gql, makeVar } from '@apollo/client';
import { NotificationTypes } from '../../contracts/types';
export const currentNotificationVar = makeVar({
    isOpen: false,
    notificationType: NotificationTypes.None,
    message: '',
    heading: '',
    subHeading: '',
    autoHideDuration: 0,
});
export const currentNotificationCachePolicy = {
    currentNotification: {
        read() {
            return currentNotificationVar();
        },
    },
};
export const currentNotificationTypeDefs = gql `
    extend type Query {
        currentNotification: CurrentNotification
    }

    enum NotificationTypes {
        Error
        Notification
    }

    type CurrentNotification {
        isOpen: Boolean!
        notificationType: NotificationTypes
        message: String
        heading: String
        subHeading: String
        autoHideDuration: Int
    }
`;
