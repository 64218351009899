import { FilterProductEnum, QueryParamsEnum } from '../enums';
export const extractFilters = (product, query) => {
    const checkboxEntries = [];
    const rangeEntries = [];
    const textEntires = [];
    Object.entries(query)
        .filter(([key, _]) => key.startsWith(`${product === FilterProductEnum.FLIGHT ? QueryParamsEnum.FLIGHTFILTERS : QueryParamsEnum.HOTELFILTERS}.`))
        .forEach(([key, value]) => {
        var _a;
        const keyArr = key.split('.');
        const groupId = keyArr[1];
        if (keyArr.length === 2) {
            if (keyArr[1] === 'hotelName') {
                textEntires.push({ id: groupId, value: (_a = value === null || value === void 0 ? void 0 : value.toString().split(',')[0]) !== null && _a !== void 0 ? _a : '' });
            }
            else {
                checkboxEntries.push({
                    id: groupId,
                    selectedIds: (value === null || value === void 0 ? void 0 : value.toString().split(',')) || [],
                });
            }
        }
        else if (keyArr.length === 3) {
            const index = rangeEntries.findIndex((group) => group.id && group.id === groupId);
            const rangeValue = Number((value === null || value === void 0 ? void 0 : value.toString()) || 0);
            if (keyArr[2] === QueryParamsEnum.LOWER) {
                if (index === -1) {
                    rangeEntries.push({
                        id: groupId,
                        lower: rangeValue,
                    });
                }
                else {
                    rangeEntries[index] = Object.assign(Object.assign({}, rangeEntries[index]), { lower: rangeValue });
                }
            }
            else if (keyArr[2] === QueryParamsEnum.UPPER) {
                if (index === -1) {
                    rangeEntries.push({
                        id: groupId,
                        upper: rangeValue,
                    });
                }
                else {
                    rangeEntries[index] = Object.assign(Object.assign({}, rangeEntries[index]), { upper: rangeValue });
                }
            }
        }
    });
    return {
        checkboxFilters: Object.values(checkboxEntries).filter((group) => !!group.id && !!group.selectedIds),
        rangeFilters: Object.values(rangeEntries).filter((group) => !!group.id && (!!group.lower || group.lower === 0) && (!!group.upper || group.upper === 0)),
        textFilter: Object.values(textEntires).filter((group) => !!group.id && !!group.value),
    };
};
