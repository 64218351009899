import React, { useEffect } from 'react';
import { TypographyBody, Notification as CoreNotification, Toast, useBreakpoints, TickIcon, WarningIcon, } from '@honeycomb/ui-core';
import { useNotification, NotificationTypes, useModal } from '@honeycomb/data';
export function Notification() {
    var _a, _b, _c, _d, _e, _f;
    const [currentNotification, setCurrentNotification] = useNotification();
    const { data } = currentNotification;
    const isOpen = (_a = data === null || data === void 0 ? void 0 : data.currentNotification) === null || _a === void 0 ? void 0 : _a.isOpen;
    const notificationType = ((_b = data === null || data === void 0 ? void 0 : data.currentNotification) === null || _b === void 0 ? void 0 : _b.notificationType) || NotificationTypes.None;
    const message = (_c = data === null || data === void 0 ? void 0 : data.currentNotification) === null || _c === void 0 ? void 0 : _c.message;
    const heading = (_d = data === null || data === void 0 ? void 0 : data.currentNotification) === null || _d === void 0 ? void 0 : _d.heading;
    const subHeading = (_e = data === null || data === void 0 ? void 0 : data.currentNotification) === null || _e === void 0 ? void 0 : _e.subHeading;
    const autoHideDuration = ((_f = data === null || data === void 0 ? void 0 : data.currentNotification) === null || _f === void 0 ? void 0 : _f.autoHideDuration) || 0;
    const { mediumUp } = useBreakpoints();
    const isError = notificationType === NotificationTypes.Error;
    const closeModal = () => setCurrentNotification({ isOpen: false, notificationType });
    const [_currentModal, setCurrentModal] = useModal();
    useEffect(() => {
        if (mediumUp && isOpen && isError) {
            setCurrentModal({
                isOpen: true,
                subheading: message,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    return (React.createElement(Toast, { fullWidth: true, placement: {
            horizontal: 'center',
            vertical: 'bottom',
        }, autoHideDuration: autoHideDuration, open: isOpen && !mediumUp, onClose: closeModal, zIndexOrder: 101 },
        React.createElement(CoreNotification, { color: isError ? 'error' : 'success', icon: isError ? React.createElement(WarningIcon, null) : React.createElement(TickIcon, null), shadow: "m", "data-id": "global-toast-notification" }, !isError ? (React.createElement(React.Fragment, null,
            React.createElement(TypographyBody, { weight: "bold", "data-id": "notification-heading-message" }, heading),
            React.createElement("p", { "data-id": "notification-subheading-message" }, subHeading))) : (React.createElement(TypographyBody, { weight: "bold", "data-id": "notification-error-message" }, message)))));
}
