export const TR_MOBILE_APP_HEADER = 'x-tr-app';
export var TR_MOBILE_APP_VERSION;
(function (TR_MOBILE_APP_VERSION) {
    TR_MOBILE_APP_VERSION["IOS"] = "TRIOSApp";
    TR_MOBILE_APP_VERSION["ANDROID"] = "TRAndroidApp";
})(TR_MOBILE_APP_VERSION || (TR_MOBILE_APP_VERSION = {}));
export function isMobileAppRequest(headers) {
    const mobileAppHeader = headers !== undefined || headers !== null
        ? headers && Object.keys(headers).includes(TR_MOBILE_APP_HEADER)
        : false;
    if (!headers || !mobileAppHeader) {
        return false;
    }
    return (headers[TR_MOBILE_APP_HEADER] === TR_MOBILE_APP_VERSION.IOS ||
        headers[TR_MOBILE_APP_HEADER] === TR_MOBILE_APP_VERSION.ANDROID);
}
