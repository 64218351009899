import * as React from 'react';
import { Styler, StylerProps } from '@honeycomb/ui-core';

export function DnataTravelLogoWhite(props: StylerProps): React.JSX.Element {
    return (
        <Styler component="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 42" {...props}>
            <g clipPath="url(#clip0_1492_11110)">
                <path d="M31.3701 9.84003V15.53L37.7901 9.84003H31.3701Z" fill="white" />
                <path
                    d="M25.87 9.84H14.87C7.36 9.84 0 15.53 0 25.23C0 34.93 7.12 41.08 15.72 41.08C25.1 41.08 31.37 33.59 31.37 24.97V15.93H25.87V24.98C25.87 29.86 22.32 35.84 15.73 35.84C10.15 35.84 5.58 31.09 5.57 25.24C5.57 21.03 8.51 15.03 16.24 15.03H25.52L31.37 9.85V0H25.87V9.84Z"
                    fill="white"
                />
                <path
                    d="M54.51 8.76001C49.41 8.76001 44.62 11.63 43.08 14.5V9.85001H37.79V40.34H43.66V21.45C43.66 16.05 49.51 13.36 53.22 13.36C59.58 13.36 60.88 17.57 60.88 22.55V40.34H66.49V21.91C66.49 11.32 60.59 8.77001 54.51 8.77001"
                    fill="white"
                />
                <path
                    d="M113.77 35.94C111.07 35.94 110.29 33.8 110.29 31.62V14.84H119.6V9.84H110.29V0H104.75V32.75C104.75 35.85 106.26 41.08 112.97 41.08C115.49 41.08 118.19 40.59 119.93 39.8L118.37 34.86C116.3 35.69 114.81 35.94 113.77 35.94Z"
                    fill="white"
                />
                <path
                    d="M113.77 35.94C111.07 35.94 110.29 33.8 110.29 31.62V14.84H119.6V9.84H110.29V0H104.75V32.75C104.75 35.85 106.26 41.08 112.97 41.08C115.49 41.08 118.19 40.59 119.93 39.8L118.37 34.86C116.3 35.69 114.81 35.94 113.77 35.94Z"
                    fill="white"
                />
                <path
                    d="M136.85 9.21997C136.85 9.21997 136.85 9.21997 136.83 9.21997C130.5 9.21997 124.66 12.62 124.66 12.62L127.02 16.67C127.02 16.67 130.99 14.06 136.8 14.06C144.95 14.06 145.65 17.78 145.68 19.53V20C145.68 20 139.49 20.53 135.82 20.88C130.57 21.36 127.2 22.96 125.16 24.95C123.11 26.93 122.36 29.31 122.36 31.35V31.42C122.36 37.82 128.53 41.09 135.39 41.09C140.76 41.09 145.87 38.37 145.87 38.37V40.36H151.08V19.1C151.08 14.64 147.33 9.22997 136.86 9.22997M145.72 32.44C143.83 35.05 139.46 36.45 135.08 36.45C133 36.45 128.1 35.63 128.1 30.71C128.1 27.04 132.53 25.54 136.19 25.22C138.77 24.99 145.72 24.41 145.72 24.41V32.44Z"
                    fill="white"
                />
                <path
                    d="M99.3599 40.34V19.08C99.3599 13.85 94.7699 9.21997 85.1199 9.21997C78.9899 9.21997 72.9499 12.62 72.9499 12.62L75.3199 16.67C75.3199 16.67 79.2899 14.06 85.0899 14.06C93.2299 14.06 93.9499 17.78 93.9599 19.53V20C93.9599 20 87.7699 20.55 84.1099 20.88C78.2299 21.41 70.6499 24.11 70.6499 31.42C70.6499 37.79 76.8099 41.09 83.6799 41.09C89.0399 41.09 94.1499 38.37 94.1499 38.37V40.36H99.3599V40.34ZM83.3699 36.45C81.2799 36.45 76.3899 35.63 76.3899 30.71C76.3899 27.04 80.7999 25.54 84.4699 25.22C87.0499 24.99 93.9999 24.41 93.9999 24.41C93.9999 24.41 93.9999 31.8 93.9999 32.44C92.1199 35.05 87.7399 36.45 83.3699 36.45Z"
                    fill="white"
                />
                <path
                    d="M167.71 35.94C165.01 35.94 164.23 33.8 164.23 31.62V14.84H173.54V9.84H164.23V0H158.69V32.75C158.69 35.85 160.2 41.08 166.91 41.08C169.42 41.08 172.13 40.59 173.87 39.8L172.31 34.86C170.24 35.69 168.75 35.94 167.71 35.94Z"
                    fill="white"
                />
                <path d="M296.75 0H291.21V40.28H296.75V0Z" fill="white" />
                <path
                    d="M195.97 14.23C195.01 14.17 194.18 14.23 192.96 14.23C189.38 14.23 184.26 16.53 184.26 21.84V40.37H178.76V9.82999H183.88V14.79C185.16 10.95 190.34 9.17999 193.74 9.17999H195.98V14.23H195.97Z"
                    fill="white"
                />
                <path
                    d="M225.14 19.07C225.14 13.84 220.55 9.20001 210.9 9.20001C204.77 9.20001 198.73 12.6 198.73 12.6L201.1 16.65C201.1 16.65 205.07 14.04 210.88 14.04C219.02 14.04 219.74 17.76 219.75 19.51V19.97C219.75 19.97 213.56 20.52 209.9 20.85C204.02 21.38 196.44 24.08 196.44 31.39C196.44 37.76 202.6 41.06 209.47 41.06C214.83 41.06 219.94 38.34 219.94 38.34V40.33H225.15V19.07H225.14ZM209.16 36.44C207.07 36.44 202.18 35.62 202.18 30.7C202.18 27.03 206.59 25.54 210.26 25.22C212.84 24.99 219.78 24.41 219.78 24.41C219.78 24.41 219.78 31.8 219.78 32.43C217.9 35.04 213.52 36.44 209.15 36.44"
                    fill="white"
                />
                <path
                    d="M238.73 40.32L226.42 9.82001H232.45L242.05 35.56L251.97 9.82001H258.01L245.39 40.32H238.73Z"
                    fill="white"
                />
                <path
                    d="M257.88 25.13C257.88 15.94 263.35 9.26001 272.35 9.26001C282.79 9.26001 288.22 16.24 286.69 27.29H263.41C263.41 32.79 267.38 36.41 273.69 36.41C275.5 36.41 278.07 36.82 283.32 34.63L285.01 38.57C283.07 39.76 278.7 41.07 273.2 41.07C264.83 41.07 257.89 35.07 257.89 25.13M263.6 22.54H281.66C281.3 17.98 278.52 13.92 272.35 13.92C267.22 13.92 263.6 18.17 263.6 22.54Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1492_11110">
                    <rect width="296.75" height="41.08" fill="white" />
                </clipPath>
            </defs>
        </Styler>
    );
}
