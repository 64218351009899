import { gql, makeVar } from '@apollo/client';
export const initialTransferSearchParameters = {
    adults: 2,
    airportTitle: '',
    airport: '',
    childAges: [],
    endDate: '',
    resortId: 0,
    resortTitle: '',
    startDate: '',
};
export const currentTransferSearchParameters = makeVar(initialTransferSearchParameters);
export const currentTransferSearchParametersCachePolicy = {
    currentTransferSearchParameters: {
        read() {
            return currentTransferSearchParameters();
        },
    },
};
export const currentTransferSearchParametersTypeDefs = gql `
    extend type Query {
        currentTransferSearchParameters: TransferSearchRequest
    }
`;
