export function parseBoolean(value) {
    return [true, 'true', 'True', 'TRUE', '1', 1].includes(value);
}
export function capitalizeFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}
export function capitalizeEachFirstLetter(value) {
    if (!value) {
        return ' ';
    }
    const words = value.split(' ');
    const result = words.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
    });
    return result.join(' ');
}
