function isObject(item) {
    return typeof item === 'object' && !Array.isArray(item) && item !== null;
}
export function interjectResourceString(resourceStr, placeholder) {
    var _a;
    let sentence;
    const placeholderRegEx = /{([0-9]+)}/g;
    if (!resourceStr) {
        return '';
    }
    // if there are multiple placeholders {0} {1} {3} etc this bit will update the resourceStr string placeholders
    if (placeholder && isObject(placeholder)) {
        const values = Object.values(placeholder);
        sentence = resourceStr.replace(placeholderRegEx, (_, $1) => $1 && values[$1]);
    }
    if (typeof placeholder === 'string' || typeof placeholder === 'number') {
        sentence = resourceStr.replace('{0}', String(placeholder));
    }
    return (_a = (sentence || resourceStr)) !== null && _a !== void 0 ? _a : '';
}
