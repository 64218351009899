import Router from 'next/router';
export const router = {
    push: (params, calculateRoute) => {
        var _a, _b;
        const currentRoute = { route: (_a = Router === null || Router === void 0 ? void 0 : Router.router) === null || _a === void 0 ? void 0 : _a.route, query: (_b = Router === null || Router === void 0 ? void 0 : Router.router) === null || _b === void 0 ? void 0 : _b.query };
        return Router.push(...calculateRoute(params, currentRoute));
    },
    replace: (params, calculateRoute) => {
        var _a, _b;
        const currentRoute = { route: (_a = Router === null || Router === void 0 ? void 0 : Router.router) === null || _a === void 0 ? void 0 : _a.route, query: (_b = Router === null || Router === void 0 ? void 0 : Router.router) === null || _b === void 0 ? void 0 : _b.query };
        return Router.replace(...calculateRoute(params, currentRoute));
    },
    query: () => { var _a; return ((Router === null || Router === void 0 ? void 0 : Router.router) ? (_a = Router === null || Router === void 0 ? void 0 : Router.router) === null || _a === void 0 ? void 0 : _a.query : undefined); },
    currentRoute: () => {
        // Todo! need to check, how we can use router without window check currently throwing error router null error.
        var _a;
        if (typeof window !== 'undefined') {
            return (_a = Router.router) === null || _a === void 0 ? void 0 : _a.asPath.split('?')[0];
        }
        return undefined;
    },
    currentParams: () => {
        var _a, _b;
        if (!((_a = Router.router) === null || _a === void 0 ? void 0 : _a.query))
            return new URLSearchParams();
        const params = Object.entries((_b = Router.router) === null || _b === void 0 ? void 0 : _b.query).reduce((acc, [key, val]) => {
            if (!val)
                return Object.assign({}, acc);
            if (Array.isArray(val))
                return Object.assign(Object.assign({}, acc), { [key]: val.toString() });
            return Object.assign(Object.assign({}, acc), { [key]: val });
        }, {});
        return new URLSearchParams(params);
    },
};
export const addRouterEventListener = (eventName, sideEffect) => {
    Router.events.on(eventName, (url) => {
        sideEffect(url);
    });
};
