export const clearLastViewedEstab = () => {
    return typeof window !== 'undefined' && window.localStorage.removeItem('lastViewedEstab');
};
export function setLastViewedEstab(estabState) {
    if (estabState && typeof window !== 'undefined') {
        // Set the lastViewedEstab  in local storage to be the searchData specified, if one entry exists that will be
        // updated with new search data.
        clearLastViewedEstab();
        window.localStorage.setItem('lastViewedEstab', JSON.stringify(estabState));
    }
}
export const getLastViewedEstab = () => {
    return typeof window !== 'undefined' && window.localStorage.getItem('lastViewedEstab');
};
