import { PriceDisplayMode, QueryParamsEnum } from '@honeycomb/data';
import { QueryParamManager } from '../utils/QueryParamManager';
import { router } from '../utils/router';
// Following function will update the query param with the updated value.
export function priceDisplayModeToggleRouting(priceDisplayMode) {
    var _a, _b;
    const params = new QueryParamManager((_a = router.currentRoute()) !== null && _a !== void 0 ? _a : '/', router.currentParams());
    const hasPriceDisplayMode = (_b = router === null || router === void 0 ? void 0 : router.query()) === null || _b === void 0 ? void 0 : _b[QueryParamsEnum.PRICE_DISPLAY_MODE];
    if (hasPriceDisplayMode) {
        params.remove(QueryParamsEnum.PRICE_DISPLAY_MODE);
    }
    if (priceDisplayMode === PriceDisplayMode.PerPersonPrice) {
        params.add(QueryParamsEnum.PRICE_DISPLAY_MODE, PriceDisplayMode.PerPersonPrice);
    }
    router.replace(router.currentRoute(), () => [params.toString(), undefined, { shallow: true }]);
}
