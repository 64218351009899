var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation, useReactiveVar } from '@apollo/client';
import { callSideEffects, convertSearchStateToHotelRequest, convertSearchStateToHolidayRequest, convertSearchStateToFlightRequest, convertSearchStateToFlexibleHotelRequest, convertSearchStateToFlexibleHolidayRequest, } from '../../util';
import { currentTripStateIdVar } from './currentTripState';
import { initialSearchParameters } from './currentSearchParameters';
import { TripType, } from '../../contracts/types';
import { currentRoomsVar, defaultCurrentRooms } from '../rooms';
import { currentAvailableHotelFiltersVar } from '../filter';
import { initializeApollo } from '../../apollo/apollo-client';
import { FLEXIBLE_HOLIDAY_SEARCH_MUTATION, FLEXIBLE_HOTEL_SEARCH_MUTATION, FLIGHT_SEARCH_MUTATION, HOLIDAY_SEARCH_MUTATION, HOTEL_SEARCH_MUTATION, SEARCH_PARAMS_QUERY, TRANSFER_SEARCH_MUTATION, searchParametersFragment, } from './searchGql';
import { convertSearchStateToTransferSearchRequest } from '../../util/convertSearchStateToTransferSearchRequest';
export const readSearchParamFragment = (tripStateId) => {
    var _a;
    const client = initializeApollo();
    return (_a = initializeApollo().readFragment({
        id: client.cache.identify({ tripStateId, __typename: 'TripState' }),
        fragment: searchParametersFragment,
        fragmentName: 'SearchParameters',
    })) === null || _a === void 0 ? void 0 : _a.searchParameters;
};
export const readSearchParamFromQuery = (tripStateId) => {
    const data = initializeApollo().readQuery({
        query: SEARCH_PARAMS_QUERY,
        variables: {
            tripStateId,
        },
    });
    return data === null || data === void 0 ? void 0 : data.getTripState.searchParameters;
};
export function useSearch(sideEffects) {
    var _a, _b, _c, _d, _e;
    const [hotelSearchMutation, { error: hotelError, loading: hotelLoading }] = useMutation(HOTEL_SEARCH_MUTATION);
    const [flexibleHotelSearchMutation, { error: flexibleHotelError, loading: flexibleHotelLoading }] = useMutation(FLEXIBLE_HOTEL_SEARCH_MUTATION);
    const [flexibleHolidaySearchMutation, { error: flexibleHolidayError, loading: flexibleHolidayLoading }] = useMutation(FLEXIBLE_HOLIDAY_SEARCH_MUTATION);
    const [holidaySearchMutation, { error: holidayError, loading: holidayLoading }] = useMutation(HOLIDAY_SEARCH_MUTATION);
    const [flightSearchMutation, { error: flightError, loading: flightLoading }] = useMutation(FLIGHT_SEARCH_MUTATION);
    const [transferSearchMutation, { error: transferError, loading: transferLoading }] = useMutation(TRANSFER_SEARCH_MUTATION);
    const setSearch = {
        createSearch: (state) => __awaiter(this, void 0, void 0, function* () {
            var _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
            let tripStateId;
            let searchParameters;
            switch (state.tripType) {
                case TripType.Holiday: {
                    if ((_f = state.destinations) === null || _f === void 0 ? void 0 : _f.length) {
                        const holidayArgs = convertSearchStateToFlexibleHolidayRequest(state);
                        const { data: flexibleHolidayMutationData } = yield flexibleHolidaySearchMutation({
                            variables: {
                                request: holidayArgs,
                            },
                        });
                        tripStateId = (_h = (_g = flexibleHolidayMutationData === null || flexibleHolidayMutationData === void 0 ? void 0 : flexibleHolidayMutationData.flexibleHolidaySearch) === null || _g === void 0 ? void 0 : _g.tripStateId) !== null && _h !== void 0 ? _h : '';
                        searchParameters = (_k = (_j = flexibleHolidayMutationData === null || flexibleHolidayMutationData === void 0 ? void 0 : flexibleHolidayMutationData.flexibleHolidaySearch) === null || _j === void 0 ? void 0 : _j.searchParameters) !== null && _k !== void 0 ? _k : null;
                    }
                    else {
                        const holidayArgs = convertSearchStateToHolidayRequest(state);
                        const { data: holidayMutationData } = yield holidaySearchMutation({
                            variables: {
                                request: holidayArgs,
                            },
                        });
                        tripStateId = ((_l = holidayMutationData === null || holidayMutationData === void 0 ? void 0 : holidayMutationData.holidaySearch) === null || _l === void 0 ? void 0 : _l.tripStateId) || '';
                        searchParameters = ((_m = holidayMutationData === null || holidayMutationData === void 0 ? void 0 : holidayMutationData.holidaySearch) === null || _m === void 0 ? void 0 : _m.searchParameters) || null;
                    }
                    break;
                }
                case TripType.Hotel: {
                    if ((_o = state.destinations) === null || _o === void 0 ? void 0 : _o.length) {
                        const hotelArgs = convertSearchStateToFlexibleHotelRequest(state);
                        const { data: flexibleHotelMutationData } = yield flexibleHotelSearchMutation({
                            variables: {
                                request: hotelArgs,
                            },
                        });
                        tripStateId = (_q = (_p = flexibleHotelMutationData === null || flexibleHotelMutationData === void 0 ? void 0 : flexibleHotelMutationData.flexibleHotelSearch) === null || _p === void 0 ? void 0 : _p.tripStateId) !== null && _q !== void 0 ? _q : '';
                        searchParameters = (_s = (_r = flexibleHotelMutationData === null || flexibleHotelMutationData === void 0 ? void 0 : flexibleHotelMutationData.flexibleHotelSearch) === null || _r === void 0 ? void 0 : _r.searchParameters) !== null && _s !== void 0 ? _s : null;
                    }
                    else {
                        const hotelArgs = convertSearchStateToHotelRequest(state);
                        const { data: hotelMutationData } = yield hotelSearchMutation({
                            variables: {
                                request: hotelArgs,
                            },
                        });
                        tripStateId = ((_t = hotelMutationData === null || hotelMutationData === void 0 ? void 0 : hotelMutationData.hotelSearch) === null || _t === void 0 ? void 0 : _t.tripStateId) || '';
                        searchParameters = ((_u = hotelMutationData === null || hotelMutationData === void 0 ? void 0 : hotelMutationData.hotelSearch) === null || _u === void 0 ? void 0 : _u.searchParameters) || null;
                    }
                    break;
                }
                case TripType.Flight: {
                    const flightArgs = convertSearchStateToFlightRequest(state);
                    const { data: flightMutationData } = yield flightSearchMutation({
                        variables: {
                            request: flightArgs,
                        },
                    });
                    tripStateId = ((_v = flightMutationData === null || flightMutationData === void 0 ? void 0 : flightMutationData.flightSearch) === null || _v === void 0 ? void 0 : _v.tripStateId) || '';
                    searchParameters = ((_w = flightMutationData === null || flightMutationData === void 0 ? void 0 : flightMutationData.flightSearch) === null || _w === void 0 ? void 0 : _w.searchParameters) || null;
                    break;
                }
                case TripType.Transfer: {
                    const transferData = convertSearchStateToTransferSearchRequest(state);
                    const { data: transferMutationData } = yield transferSearchMutation({
                        variables: {
                            request: transferData,
                        },
                    });
                    tripStateId = ((_x = transferMutationData === null || transferMutationData === void 0 ? void 0 : transferMutationData.transferSearch) === null || _x === void 0 ? void 0 : _x.tripStateId) || '';
                    searchParameters = ((_y = transferMutationData === null || transferMutationData === void 0 ? void 0 : transferMutationData.transferSearch) === null || _y === void 0 ? void 0 : _y.searchParameters) || null;
                    break;
                }
                default:
                    break;
            }
            if (tripStateId) {
                if (state.tripType !== TripType.Transfer) {
                    currentRoomsVar(defaultCurrentRooms);
                    currentAvailableHotelFiltersVar(undefined);
                }
                currentTripStateIdVar(tripStateId);
                callSideEffects(sideEffects, searchParameters);
            }
        }),
        error: (_d = (_c = (_b = (_a = holidayError !== null && holidayError !== void 0 ? holidayError : hotelError) !== null && _a !== void 0 ? _a : flightError) !== null && _b !== void 0 ? _b : flexibleHotelError) !== null && _c !== void 0 ? _c : flexibleHolidayError) !== null && _d !== void 0 ? _d : transferError,
        loading: holidayLoading ||
            hotelLoading ||
            flightLoading ||
            flexibleHotelLoading ||
            flexibleHolidayLoading ||
            transferLoading,
    };
    const searchStateFromFragement = readSearchParamFragment(useReactiveVar(currentTripStateIdVar));
    const searchStateFromQuery = readSearchParamFromQuery(useReactiveVar(currentTripStateIdVar));
    const searchState = (_e = searchStateFromFragement !== null && searchStateFromFragement !== void 0 ? searchStateFromFragement : searchStateFromQuery) !== null && _e !== void 0 ? _e : initialSearchParameters;
    return [searchState, setSearch];
}
