import { ApolloClient, ApolloLink, HttpLink, from, InMemoryCache, defaultDataIdFromObject, } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import getConfig from 'next/config';
import fetch from 'cross-fetch';
import { useMemo } from 'react';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { currentOpenSearchFieldCachePolicy, currentOpenSearchFieldTypeDefs, currentSortCriterionCachePolicy, currentSortCriterionTypeDefs, currentTransferSearchParametersCachePolicy, currentFlightFiltersCachePolicy, currentFlightFiltersTypeDefs, currentHotelFiltersCachePolicy, currentHotelFiltersTypeDefs, currentModalCachePolicy, currentModalTypeDefs, currentRoomsCachePolicy, currentRoomsTypeDefs, currentSearchParametersCachePolicy, currentSearchParametersTypeDefs, currentTripStateIdCachePolicy, currentTripStateIdTypeDefs, } from '../client';
import { currentTransferAirportTypeDefs, currentTransferAirportCachePolicy, } from '../client/transfer-search/currentTransferAirport';
import { isPageRoutingCachePolicy, isPageRoutingTypeDefs } from '../client/page-routing/usePageRouting';
import { toIPv4 } from '../util';
import { currentNotificationCachePolicy, currentNotificationTypeDefs, } from '../client/notification/currentNotification';
export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
let apolloClient;
const isServer = typeof window === 'undefined';
const isTest = process.env.NODE_ENV === 'test';
const typeDefs = [
    currentTripStateIdTypeDefs,
    currentSortCriterionTypeDefs,
    currentModalTypeDefs,
    currentRoomsTypeDefs,
    currentSearchParametersTypeDefs,
    currentHotelFiltersTypeDefs,
    currentFlightFiltersTypeDefs,
    currentOpenSearchFieldTypeDefs,
    isPageRoutingTypeDefs,
    currentTransferAirportTypeDefs,
    currentNotificationTypeDefs,
];
export function getGraphqlUrl() {
    var _a;
    const config = ((_a = getConfig()) === null || _a === void 0 ? void 0 : _a.publicRuntimeConfig) || {};
    const { graphqlUrl } = isServer && config; // at client requests are being proxyed
    const testGraphqlUrl = (isTest || config.mockingEnabled === 'true') && 'http://example.com/graphql'; // we just need any absolute URL ending /graphql for MSW to intercept
    return graphqlUrl || testGraphqlUrl || '/graphql';
}
function getClientIp(context) {
    var _a, _b, _c, _d;
    if (!context)
        return undefined;
    const forwarded = (_a = context.req) === null || _a === void 0 ? void 0 : _a.headers['x-forwarded-for'];
    if (typeof forwarded === 'string') {
        return toIPv4(forwarded || ((_c = (_b = context.req) === null || _b === void 0 ? void 0 : _b.socket) === null || _c === void 0 ? void 0 : _c.remoteAddress) || undefined);
    }
    return toIPv4(forwarded ? forwarded[0] : ((_d = context.req.socket) === null || _d === void 0 ? void 0 : _d.remoteAddress) || undefined);
}
function getUserAgentHints(request) {
    var _a, _b;
    let hints = {};
    if (request !== undefined && request !== null) {
        (_b = (_a = Object.keys(request === null || request === void 0 ? void 0 : request.headers)) === null || _a === void 0 ? void 0 : _a.filter((key) => key.includes('sec-ch-ua'))) === null || _b === void 0 ? void 0 : _b.forEach((key) => {
            hints = Object.assign(Object.assign({}, hints), { [key]: request === null || request === void 0 ? void 0 : request.headers[key] });
        });
    }
    return hints;
}
function createApolloClient(context) {
    var _a;
    const { clientAppName, clientAppVersion } = ((_a = getConfig()) === null || _a === void 0 ? void 0 : _a.publicRuntimeConfig) || {};
    const httpLink = new HttpLink({
        uri: (operation) => `${getGraphqlUrl()}/${operation.operationName.toLowerCase()}`,
        fetch,
        credentials: 'include',
    });
    const userAgentHints = isServer ? getUserAgentHints(context === null || context === void 0 ? void 0 : context.req) : {};
    const headersLink = new ApolloLink((operation, forward) => {
        var _a, _b, _c;
        const brandContext = (_a = context === null || context === void 0 ? void 0 : context.query) === null || _a === void 0 ? void 0 : _a.brandContext;
        const mockErrors = (_c = (isServer
            ? (_b = context === null || context === void 0 ? void 0 : context.req.cookies) === null || _b === void 0 ? void 0 : _b['x-mock-errors-enabled']
            : document.cookie.includes('x-mock-errors-enabled=true'))) !== null && _c !== void 0 ? _c : false;
        operation.setContext(({ headers }) => ({
            headers: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, headers), { 'X-TRP-Correlation-Id': context === null || context === void 0 ? void 0 : context.req.headers['x-trp-correlation-id'], 'x-original-origin': context === null || context === void 0 ? void 0 : context.req.headers.host, 'x-forwarded-for': getClientIp(context), 'x-mock-errors-enabled': mockErrors }), (brandContext ? { 'x-brand-context': brandContext || 'hc' } : {})), userAgentHints), { 'user-agent': (context === null || context === void 0 ? void 0 : context.req.headers['user-agent']) || '' }),
        }));
        return forward(operation);
    });
    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
        const errorLoggingrUrl = `/log/error`;
        const { operationName } = operation;
        if (graphQLErrors) {
            graphQLErrors.forEach((error) => {
                console.error(`[GraphQL Error]: ${JSON.stringify(error)}`);
                if (!isServer && !isTest) {
                    fetch(errorLoggingrUrl, {
                        method: 'post',
                        headers: Object.assign(Object.assign({}, operation.getContext().headers), { 'content-type': 'application/json' }),
                        body: JSON.stringify({
                            message: error.message,
                            stack: error.stack,
                            source: 'ApolloClient',
                            operationName,
                        }),
                    });
                }
            });
        }
        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
            if (!isServer && !isTest) {
                fetch(errorLoggingrUrl, {
                    method: 'post',
                    headers: Object.assign(Object.assign({}, operation.getContext().headers), { 'content-type': 'application/json' }),
                    body: JSON.stringify({
                        message: networkError.message,
                        stack: networkError.stack,
                        source: 'ApolloClientNetwork',
                        operationName,
                    }),
                });
            }
        }
    });
    const cache = new InMemoryCache({
        typePolicies: {
            Query: {
                fields: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, currentTripStateIdCachePolicy), currentSortCriterionCachePolicy), currentModalCachePolicy), currentRoomsCachePolicy), currentSearchParametersCachePolicy), currentHotelFiltersCachePolicy), currentFlightFiltersCachePolicy), currentOpenSearchFieldCachePolicy), isPageRoutingCachePolicy), currentTransferAirportCachePolicy), currentNotificationCachePolicy), currentTransferSearchParametersCachePolicy), { getEstabById: {
                        keyArgs: ['estabId'],
                        merge(existing, incoming, { variables }) {
                            var _a, _b, _c, _d;
                            let mergedReviews = (existing === null || existing === void 0 ? void 0 : existing.reviews) ? [...existing.reviews] : [];
                            const incomingReviews = incoming.reviews;
                            const reviewsPagingLimit = (_b = (_a = variables === null || variables === void 0 ? void 0 : variables.request) === null || _a === void 0 ? void 0 : _a.pagination) === null || _b === void 0 ? void 0 : _b.limit;
                            const reviewsPagingOffset = (_d = (_c = variables === null || variables === void 0 ? void 0 : variables.request) === null || _c === void 0 ? void 0 : _c.pagination) === null || _d === void 0 ? void 0 : _d.offSet;
                            if (reviewsPagingLimit && incomingReviews) {
                                const offset = (reviewsPagingOffset || 0) * reviewsPagingLimit;
                                for (let i = 0; i < incomingReviews.length; i += 1) {
                                    mergedReviews[offset + i] = incomingReviews[i];
                                }
                            }
                            else {
                                mergedReviews = [...mergedReviews, ...(incomingReviews || [])];
                            }
                            return Object.assign(Object.assign({}, incoming), { reviews: mergedReviews });
                        },
                    }, hotelSearchResults: {
                        keyArgs: ['tripStateId', 'filters', 'sortCriterion'],
                        merge(existing, incoming) {
                            let mergedHotels = (existing === null || existing === void 0 ? void 0 : existing.hotels) ? [...existing.hotels] : [];
                            const incomingHotels = incoming === null || incoming === void 0 ? void 0 : incoming.hotels;
                            // Note: args comes from from third merge() parameter `{ args }`
                            // if (args?.paging?.limit && incomingHotels) {
                            //     const offset = (args?.paging?.offset || 0) * args.paging.limit;
                            //     for (let i = 0; i < incomingHotels.length; i += 1) {
                            //         mergedHotels[offset + i] = incomingHotels[i];
                            //     }
                            // } else {
                            //     mergedHotels = [...mergedHotels, ...(incomingHotels || [])];
                            // }
                            // Dumb merge function required due to broken contract between graphql-client (please use function above once fixed)
                            mergedHotels = [...mergedHotels, ...(incomingHotels || [])];
                            return Object.assign(Object.assign({}, incoming), { hotels: mergedHotels });
                        },
                    }, flightSearchResults: {
                        keyArgs: ['tripStateId', 'filters', 'sortCriterion'],
                        merge(existing, incoming, { args }) {
                            var _a, _b, _c;
                            const existingFlights = (existing === null || existing === void 0 ? void 0 : existing.flights) ? [...existing.flights] : [];
                            const incomingFlights = incoming === null || incoming === void 0 ? void 0 : incoming.flights;
                            let mergedFlights = existingFlights && ((_a = args === null || args === void 0 ? void 0 : args.paging) === null || _a === void 0 ? void 0 : _a.pageIndex) > 0 ? existingFlights.slice(0) : [];
                            if (((_b = args === null || args === void 0 ? void 0 : args.paging) === null || _b === void 0 ? void 0 : _b.pageSize) && incomingFlights) {
                                const offset = (((_c = args === null || args === void 0 ? void 0 : args.paging) === null || _c === void 0 ? void 0 : _c.pageIndex) || 0) * args.paging.pageSize;
                                for (let i = 0; i < incomingFlights.length; i += 1) {
                                    mergedFlights[offset + i] = incomingFlights[i];
                                }
                            }
                            else
                                mergedFlights = [...mergedFlights, ...(incomingFlights || [])];
                            return Object.assign(Object.assign({}, incoming), { flights: mergedFlights });
                        },
                    }, destinationAutocompleterResults: {
                        keyArgs: ['request'],
                    }, airportAutocompleterResults: {
                        keyArgs: ['request'],
                    }, signedInCustomer: {
                        keyArgs: false,
                    }, getCsrs: {
                        keyArgs: false,
                    }, findAddresses: {
                        keyArgs: false,
                    }, retrieveAddress: {
                        keyArgs: false,
                    }, placesAutocompleteService: {
                        keyArgs: ['input'],
                    }, placesDetailService: {
                        keyArgs: false,
                    } }),
            },
            Hotel: {
                fields: {
                    reviews: {
                        keyArgs: false,
                    },
                },
            },
            CheckboxFilterGroupItem: {
                keyFields: false,
            },
            RangeFilterGroup: {
                keyFields: false,
            },
            CheckboxFilterGroup: {
                keyFields: false,
            },
            TextFilter: {
                keyFields: false,
            },
            SingleAirport: {
                keyFields: ['code'],
            },
            BalanceSummary: {
                keyFields: false || [],
            },
            Booking: {
                keyFields: ['bookingId'],
            },
            FindAddressesItem: {
                keyFields: ['id'],
            },
            RetrieveAddressResult: {
                keyFields: false,
            },
            OptionalTransferExtra: {
                keyFields: ['combinedId', 'transferMinutes'],
            },
            PlaceAutocompletePrediction: {
                keyFields: ['placeId'],
            },
            PlacesDetailsResponse: {
                keyFields: false,
            },
        },
        dataIdFromObject(responseObject) {
            // eslint-disable-next-line no-underscore-dangle
            switch (responseObject.__typename) {
                case 'SearchParameters':
                    return `SearchParameters:${responseObject.tripStateId}`;
                case 'Basket':
                    return 'Basket:1';
                case 'TripState':
                    return `TripState:${responseObject.tripStateId}`;
                case 'FlightAvailabilityResult':
                    return `FlightAvailabilityResult:${responseObject.flightId}`;
                default:
                    return defaultDataIdFromObject(responseObject);
            }
        },
    });
    // async function setLocal() {
    //     if (typeof window === 'undefined') {
    //         return;
    //     }
    //     await persistCache({
    //         cache,
    //         storage: new LocalStorageWrapper(window.localStorage),
    //     });
    // }
    // setLocal();
    return new ApolloClient({
        link: from([headersLink, errorLink, httpLink]),
        cache,
        typeDefs,
        connectToDevTools: true,
        name: clientAppName,
        version: clientAppVersion,
        ssrMode: isServer,
        defaultOptions: typeof window === 'undefined'
            ? {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
            }
            : undefined,
    });
}
export function initializeApollo(context, initialState = null) {
    const apolloClientCp = apolloClient !== null && apolloClient !== void 0 ? apolloClient : createApolloClient(context);
    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = apolloClientCp.extract();
        // Merge the existing cache into data passed from getServerSideProps
        const data = merge(initialState, existingCache, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
            ],
        });
        // Restore the cache with the merged data
        apolloClientCp.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (isServer)
        return apolloClientCp;
    // Create the Apollo Client once in the client
    if (!apolloClient)
        apolloClient = apolloClientCp;
    return apolloClientCp;
}
export function addApolloState(client, pageProps) {
    if (pageProps === null || pageProps === void 0 ? void 0 : pageProps.props) {
        /* eslint-disable-next-line no-param-reassign */
        pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }
    return pageProps;
}
export function useApollo(pageProps) {
    const state = pageProps[APOLLO_STATE_PROP_NAME];
    return useMemo(() => initializeApollo(pageProps.context, state), [state, pageProps.context]);
}
export function evictCache(id, fieldName, broadcast = false) {
    initializeApollo().cache.evict({ id, fieldName, broadcast });
    initializeApollo().cache.gc();
}
