import { gql, makeVar, useQuery } from '@apollo/client';
export var SearchFieldEnum;
(function (SearchFieldEnum) {
    SearchFieldEnum["DESTINATION"] = "Destination";
    SearchFieldEnum["AIRPORTS"] = "Airports";
    SearchFieldEnum["ARRIVAL_AIRPORTS"] = "ArrivalAirports";
    SearchFieldEnum["DATES"] = "Dates";
    SearchFieldEnum["ROOMS"] = "Rooms";
    SearchFieldEnum["NONE"] = "None";
    SearchFieldEnum["PASSENGERS"] = "Passengers";
    SearchFieldEnum["PICKUPPOINT"] = "PickUpPoint";
    SearchFieldEnum["DROPOFFPOINT"] = "DropOffPoint";
    SearchFieldEnum["STARTDATE"] = "StartDate";
    SearchFieldEnum["ENDDATE"] = "EndDate";
    SearchFieldEnum["OCCUPANTS"] = "Occupants";
    SearchFieldEnum["CHECKIN_DATE"] = "CheckIn_Date";
})(SearchFieldEnum || (SearchFieldEnum = {}));
export const currentOpenSearchFieldVar = makeVar(SearchFieldEnum.NONE);
export const currentOpenSearchFieldCachePolicy = {
    currentOpenSearchField: {
        read() {
            return currentOpenSearchFieldVar();
        },
    },
};
export const currentOpenSearchFieldTypeDefs = gql `
    extend type Query {
        currentOpenSearchField: String
    }
`;
export function useCurrentOpenSearchField() {
    var _a;
    const OPEN_FIELD_QUERY = gql `
        query currentOpenSearchField {
            currentOpenSearchField @client
        }
    `;
    const currentState = useQuery(OPEN_FIELD_QUERY, { ssr: false });
    const setOpenField = (field) => {
        currentOpenSearchFieldVar(field);
    };
    return [((_a = currentState.data) === null || _a === void 0 ? void 0 : _a.currentOpenSearchField) || SearchFieldEnum.NONE, setOpenField];
}
