import { gql, makeVar, useQuery } from '@apollo/client';
export const currentTripStateIdVar = makeVar('');
export const currentTripStateIdCachePolicy = {
    currentTripStateId: {
        read() {
            return currentTripStateIdVar();
        },
    },
};
export const currentTripStateIdTypeDefs = gql `
    extend type Query {
        currentTripStateId: String
    }
`;
export function useCurrentTripId() {
    const TRIP_STATE_QUERY = gql `
        query currentTripStateId {
            currentTripStateId @client
        }
    `;
    const currentState = useQuery(TRIP_STATE_QUERY, { ssr: false });
    return [currentState];
}
