import { useGlobalContext } from '@honeycomb/data';
import React from 'react';
export function GoogleTagManagerNoScript() {
    const { configSettings: { HCGoogleTagManagerId } } = useGlobalContext();
    if (!HCGoogleTagManagerId)
        return null;
    return (React.createElement("noscript", { dangerouslySetInnerHTML: {
            __html: `<iframe src=https://www.googletagmanager.com/ns.html?id=${HCGoogleTagManagerId} height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        } }));
}
